import React, { useEffect, useState } from 'react'
import { Switch, Navigate, BrowserRouter as  Router, Routes, Route } from "react-router-dom";
import { createBrowserHistory } from 'history'
import Forgot from '../auth/Forgot.jsx'
import Login from '../auth/Login.jsx'
import Create from '../auth/Create.jsx'

import RutaProtegida from './auth/RutaProtegida.jsx';


import DashCliente from '../A-Bienes-Raices/dash/Cliente'
import DashClientePublico from '../A-Bienes-Raices/dash/ClientePublico'
import MenuState from '../context/menu/menuState'; 
import SignatureState from '../context/StepLogical/Signature/signatureState'

import StepLogicalState from '../context/StepLogical/steplogicalState'

import  {AuthProvider}  from './context/auth/AuthProvider'
import  {DataRequestProvider}  from './context/data/DataRequestContext'
import  {JoelReactProvider}  from '../context/logical/JoelReactContext'
import RedirectPage from '../componentes/redirect/RedirectPage'
///// aqui vamos
/// domo ////
import CreateAcc from './auth/CreateAcc'
import LoginAcc from './auth/LoginAcc'
///////////////////////

import NosotrosAcc from './site/Nosotros.jsx'
import ServiciosAcc from './site/Servicios.jsx'

import { NavJoemaProvider } from './context/nav/NavJoemaContext.jsx';
//// banca en linea  /////

import { ActJoemaProvider } from  './context/Actions/ActJoemaContext.jsx';
import { ActJoemaCuentaProvider } from  './context/Actions/ActJoemaCuentaContext.jsx';

import { ActJoemaSearchProvider } from './context/Actions/ActJoemaSearchContext.jsx';
// import './styles/bienes.css';
// import '../stylos/style.css';
// import '../stylos/dash.css';
// import '../stylos/domo.css';
import useAuth from '../hooks/useAuth.jsx';
import LoaderCenter from './componentes/Loader/LoaderCenter.jsx';
import WindowSelect from './dash/DashBoardUltimate/WindowSelect.jsx';
    



function App() {

  // const [scrollPosition, setScrollPosition] = useState(0);
  // const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  // const [isScrolled, setIsScrolled] = useState(false);
  // const [scrollElement, setScrollElement] = useState(null);
  // const handleScroll = () => {
  //     if (scrollElement) {
  //       const currentScrollPosition = scrollElement.scrollTop;
  //       setScrollPosition(currentScrollPosition);
  //       setIsScrolled(currentScrollPosition > 100 && currentScrollPosition < prevScrollPosition);
  //       setPrevScrollPosition(currentScrollPosition);
  //     }
  //   };
  
  //   useEffect(() => {
  //     if (scrollElement) {
  //       scrollElement.addEventListener('scroll', handleScroll);
  //     }
  
  //     return () => {
  //       if (scrollElement) {
  //         scrollElement.removeEventListener('scroll', handleScroll);
  //       }
  //     };
  //   }, [scrollElement, prevScrollPosition]);
  
  //   const setScrollRef = (ref) => {
  //     setScrollElement(ref);
  //   };
    window.scrollTo(0, 0)

  
  
  //////////////////// este es eel app de bienes raices
  return (

    <>
 
    <div className="App"   
    
    >

      <ActJoemaProvider>
      <NavJoemaProvider>
      <ActJoemaSearchProvider>
      <ActJoemaCuentaProvider>
      <AuthProvider>
                    <JoelReactProvider>
                    <DataRequestProvider>
                    <MenuState>
                    <StepLogicalState>
                    <SignatureState>
                        <Routes  >
                        
                            <Route index element={ <DashClientePublico/>} />
                            <Route path='/external-link' element={<RedirectPage />} />
                            <Route path="/forgot" element={ <Forgot/>} />
                            <Route path="/nos" element={ <NosotrosAcc/>} />
                            <Route path="/login" element={ <LoginAcc/>} />
                            <Route path="/create" element={ <CreateAcc/>} />
                            {/* <Route path="/logindomo" element={ <LoginDomo/>} />
                            <Route path="/createdomo" element={ <CreateDomo/>} /> */}
                            {/* <Route path="/core/:id" element={<WindowSelect />} /> */}
                            
                            <Route path="/core" element={<RutaProtegida/>} >
                            <Route index element={ <DashCliente/>} />
                            </Route>

                            <Route path="*" element={ <Navigate replace to="/" />} />
                        </Routes>
                      </SignatureState>
                      </StepLogicalState>
                      </MenuState>
                      </DataRequestProvider>
                      </JoelReactProvider>
          </AuthProvider>
          </ActJoemaCuentaProvider>

          </ActJoemaSearchProvider>           
          </NavJoemaProvider>      
          </ActJoemaProvider>
    </div>

    
    </>
  );
}

export default App;