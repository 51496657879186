import React, { useState, useEffect } from 'react'
import useAcc from '../../hooks/useAcc';
import InputDynamic from '../../componentes/InputD/InputDynamic';
import a from '../../imagenes/iconoscuenta/a.svg'
import b from '../../imagenes/iconoscuenta/b.svg'
import c from '../../imagenes/iconoscuenta/c.svg'
import useData from '../../hooks/useData';

const  InicioDeSesionYSeguridad = () => {


    const information = [
        {   id:0,
            icon:a,
            title:'¿Por qué mi información no aparece aquí?',
            cuento:'Para proteger tu identidad, ocultamos algunos datos de la cuenta.'
        }, {   id:1,
            icon:b,
            title:'¿Qué datos se pueden editar?',
            cuento:'No se pueden modificar los datos que Airbnb utiliza para verificar tu identidad. Aunque puedes modificar tus datos personales y de contacto, podríamos pedirte que verifiques tu identidad la próxima vez que hagas una reservación o crees un anuncio.'
        }, {   id:2,
            icon:c,
            title:'¿Qué información se comparte con los demás?',
            cuento:'Airbnb solo proporciona los datos de contacto de los anfitriones y los huéspedes cuando se confirma una reservación.'
        },
    ]


    const information02 = [
        {   id:0,
            icon:b,
            title:'Protege tu cuenta',
            cuento:'Responde solo a las solicitudes de personas que conozcas. Es posible que los hackers quieran acceder a tu cuenta imitando el correo electrónico de un miembro del equipo.'
        }
    ]

    const information01 = [
        {   id:0,
            icon:b,
            title:'Vamos a hacer que tu cuenta sea más segura',
            cuento:'Trabajamos constantemente en nuevas formas para aumentar la seguridad de nuestra comunidad. Es por eso que revisamos cada cuenta para asegurarnos de que sea lo más segura posible.',
            btn:'Mejorar'
        }
    ] 

    const information03 = [
        {   id:0,
            icon:a,
            title:'Cómo agregar dispositivos de personas de confianza',
            cuento:'Cuando apruebas una solicitud, le estás dando a esa persona acceso total a tu cuenta. Podrá modificar las reservaciones y enviar mensajes en tu nombre.',
            btn:'Mejorar'
        }
    ]



    const task = [
       { id:0,
        value:'INICIO DE SESIÓN'
        },
        { id:1,
            value:'SOLICITUDES DE INICIO DE SESIÓN'
            },
            { id:2,
                value:'ACCESO COMPARTIDO'
                },
    ]

    const {
        alessa,
        MeEncantas,
        focustask, setFocusTask
    } = useAcc()

    const {
        changepassword,
        ChangePassAction,
        setChangePass
    } = useData()


    const onChange = e => {
        setChangePass({
            ...changepassword,
            [e.target.name] : e.target.value,
        })
    }
    



    useEffect( () => {
        if(alessa.T == 'Inicio de sesión y seguridad') {
            setFocusTask('INICIO DE SESIÓN')
        }
    },[])



    return (
        <>
        { alessa.T == 'Inicio de sesión y seguridad' ?
                 <div className=" box-header-general">
                 <div className={`sabes-que-te-quiero`} >

                     <div>
                         <div className={`header-joema-hola-acc`}>
                             <p onClick={MeEncantas} className={`text-deco`}>
                                 Cuenta
                             </p>
                             <span>{'>'}</span>
                                 <p >{alessa.T}</p>
                         </div>
                         <div className={`header-joema-hola-title`}>
                             <p className={`Font-Love-Title`}>
                                 {alessa.T}
                             </p>
                         </div>
                     </div>
                    <section className={`master-contenedor-action-bar`}>
                     <section className={'contenedor-action-bar'}>
                      { task.map((viste)=> {
                        return (

                            <button key={viste.id} className={`clasic-name-btn ${focustask === viste.value ? 'clasic-name-btn-active' :''} `}   
                                    onClick={ () => {setFocusTask(viste.value)}}
                            >
                                {viste.value}
                            </button>

                        )
                      })

                      }

                     </section>
                     </section>

                     { focustask === 'INICIO DE SESIÓN' ? 
                        <>
                        <section className={`contenedor-doble`}>
                        <div className={`bombaso`}>
                                                    <div className={`tittle-space`}>
                                                        <h2>
                                                        Inicia sesión
                                                        </h2>

                                                    </div>
                                                    <InputDynamic
                                                        inputname={'Contraseña'}
                                                        inputlabel={'Última actualización: hace 3 meses'}
                                                        info={'¿Necesitas una contraseña nueva?'}
                                                        firstlabel={'Contraseña actual'}
                                                        seclabel={'Contraseña nueva'}
                                                        treslabel={'Confirma tu contraseña'}
                                                        action={'Actualizar contraseña'}
                                                        model={6}
                                                        onFocus 

                                                        id1={"current_password"}
                                                        name1={"current_password"}
                                                        type1={"password"}
                                                        value1={changepassword.current_password	 == null ? '' : changepassword.current_password}

                                                        id2={"new_password"}
                                                        name2={"new_password"}
                                                        type2={"password"}
                                                        value2={changepassword.new_password	 == null ? '' : changepassword.new_password}

                                                        id3={"confirm_password"}
                                                        name3={"confirm_password"}
                                                        type3={"password"}
                                                        value3={changepassword.confirm_password	 == null ? '' : changepassword.confirm_password}
                    
                                                        ApiCall={ChangePassAction}
                                                        onChange={onChange}




                                                    />

                                                    <div className={`tittle-space`}>
                                                        <h2>
                                                        Cuentas sociales
                                                        </h2>

                                                    </div>

                                                    <InputDynamic
                                                        inputname={'Facebook'}
                                                        inputlabel={'Desconectado'}
                                                        info={'Conectar con Facebook'}
                                                        firstlabel={'Conectar con Facebook'}
                                                        action={'Conectar'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                    <InputDynamic
                                                        inputname={'Google'}
                                                        inputlabel={'Desconectado'}
                                                        info={'Conectar con Google'}
                                                        firstlabel={'Conectar con Google'}
                                                        action={'Conectar'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                    <div className={`tittle-space`}>
                                                        <h2>
                                                        Historia del dispositivo
                                                        </h2>

                                                    </div>

                                                    <InputDynamic
                                                        inputname={'Windows 10.0 · Firefox'}
                                                        inputlabel={'Panama City, Provincia de Panama · el 22 de marzo de 2023 a las 20:26'}
                                                        info={'Panama City, Provincia de Panama · el 22 de marzo de 2023 a las 20:26'}
                                                        firstlabel={'Windows 10.0 · Firefox'}
                                                        action={'Cerrar sesión en el dispositivo'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                    

                                


                                                </div>
                                                <div >


                                                    <div className={`information-box-box-container`}>
                                                    {  information01.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                            <img src={m.icon}/>  
                                                                        <h3>{m.title}</h3>
                                                                        <p>{m.cuento}</p>
                                                                       {
                                                                        m.btn ? <>
                                                                        <button className={`joema-btn-special`}>
                                                                            {m.btn}
                                                                        </button>                 
                                                                        </> : null
                                                                       }
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>

                                                    <section>
                                                    <div className={`tittle-space`}>
                                                        <h2>
                                                        Cuenta
                                                        </h2>

                                                    </div>

                                                    <InputDynamic
                                                        inputname={'Desactiva tu cuenta'}
                                                        inputlabel={'Desactiva tu cuenta'}
                                                        info={'Desactiva tu cuenta'}
                                                        firstlabel={'Desactiva tu cuenta'}
                                                        action={'Desactivar'}
                                                        model={1}
                                                        onFocus
                                                    />
                                                    </section>

                                            </> : null
                                        }
                                        

                                        
                     { focustask === 'SOLICITUDES DE INICIO DE SESIÓN' ? 
                        <>
                        <section className={`contenedor-doble`}>
                             <div className={`bombaso`}>

                                                <div className={`tittle-space`}>
                                                        <h2>
                                                        Solicitudes de inicio de sesión
                                                        </h2>
                                                        <p>
                                                        Es posible que los miembros del equipo tengan que ingresar un código de verificación cuando inicien sesión por primera vez en esta cuenta. Aprueba o rechaza sus solicitudes de inicio de sesión en esta página.
                                                        </p>

                                                    </div>
                                                    

                                                    <InputDynamic
                                                        inputname={'Solicitudes pendientes'}
                                                        inputlabel={'Solicitudes pendientes'}
                                                        info={'Solicitudes pendientes'}
                                                        firstlabel={'Solicitudes pendientes'}
                                                        seclabel={''}
                                                        action={'Verificar'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                    <InputDynamic
                                                        inputname={'Solicitudes aprobadas'}
                                                        inputlabel={'Solicitudes aprobadas'}
                                                        info={'Solicitudes aprobadas'}
                                                        firstlabel={'Solicitudes aprobadas'}
                                                        seclabel={''}
                                                        action={'Verificar'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                    <InputDynamic
                                                        inputname={'Satisfied requests'}
                                                        inputlabel={'Satisfied requestsentes'}
                                                        info={'Satisfied requests'}
                                                        firstlabel={'Satisfied requests'}
                                                        seclabel={''}
                                                        action={'Verificar'}
                                                        model={1}
                                                        onFocus
                                                    />

                                                    <InputDynamic
                                                        inputname={'Solicitudes rechazadas'}
                                                        inputlabel={'Solicitudes rechazadas'}
                                                        info={'Solicitudes rechazadas'}
                                                        firstlabel={'Solicitudes rechazadas'}
                                                        seclabel={''}
                                                        action={'Verificar'}
                                                        model={1}
                                                        onFocus
                                                    />






                                                    

                                            

                                             



                                                </div>
                                                <div >


                                                    <div className={`information-box-box-container`}>
                                                    {  information02.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                            <img src={m.icon}/>  
                                                                        <h3>{m.title}</h3>
                                                                        <p>{m.cuento}</p>
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                            </> : null
                                        }
                                        

                                        
                     { focustask === 'ACCESO COMPARTIDO' ? 
                        <>
                        <section className={`contenedor-doble`}>
                                 <div className={`bombaso`}>
                                               
                                                <div className={`tittle-space`}>
                                                        <h2>
                                                        Acceso compartido
                                                        </h2>
                                                        <p>
                                                        Consulta cuidadosamente todas las solicitudes antes de aprobar el acceso. Le enviaremos a tu empleado o colega un correo electrónico con un código de 4 dígitos para iniciar sesión en tu cuenta con su dispositivo de confianza.
                                                        </p>

                                                    </div>
                                                    
                                                    

                                                    

                                                


                                                </div>
                                                <div >


                                                    <div className={`information-box-box-container`}>
                                                    {  information03.map((m) => {
                                                                return(
                                                                    <div key={m.id} className={`elmentaohijo`}> 
                                                                            <img src={m.icon}/>  
                                                                        <h3>{m.title}</h3>
                                                                        <p>{m.cuento}</p>
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                    </div>
                                                    
                                                </div>

                     </section>
                                            </> : null
                                        }
                                        
                    

                 </div>

                
          </div> : null

        }
        </>

    );
}
 
export default InicioDeSesionYSeguridad;