import React, {useEffect, useState, useRef} from 'react'
import like from '../../imagenes/iconos/like.svg'
import Slider from "react-slick";
import star from '../../imagenes/iconos/star.svg'
import useAct from '../../hooks/useAct';

import { dataPropiedadesUpdate } from '../../microdata/dataPropiedadesUpdate';
import useSearchJoe from '../../hooks/useSearchJoe';



const ManualImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [clicked, setClicked] = useState(false);

  const nextImage = (e) => {
    e.stopPropagation();
    // setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);

    setClicked(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setClicked(false);
    }, 100);
  };

  const prevImage = (e) => {
    e.stopPropagation();
    // setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setClicked(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setClicked(false);
    }, 100);
  };

  return (
    <div className={`manual-slider ${clicked ? 'clicked' : ''}`}>
        <div className="btn-slider-container">
      <button onClick={prevImage}>{"<"}</button>
      <button onClick={nextImage}>{">"}</button>
      </div>
      <div className="img-container-pro" style={{ background: `url(${images[currentImageIndex].picture})` }}></div>
    
    </div>
  );
};




const UltraPropiedades = () => {

        const settings = {
            dots: true,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,  
        };
            const {
            searchingTerm,
            term,
            addTermFinal,
            onChange,
            finalterm,
            filtro,
            loeader,
            oloader,
             setCantBusqueda
        } = useSearchJoe()
        const {
            WinAct,
            img1win, setImg1,
            img2win, setImg2,
            img3win, setImg3,
            img4win, setImg4,
            img5win, setImg5,
            setProp,
            Prop,
            scrollPosition, setScrollPosition,
            prevScrollPosition, setPrevScrollPosition,
            setIsScrolled,
            handleClick
        } = useAct()

        const scrollElementRef = useRef(null);
        const [scrollElement, setScrollElement] = useState(null);
        const handleScroll = () => {
            if (scrollElement) {
              const currentScrollPosition = scrollElement.scrollTop;
              setScrollPosition(currentScrollPosition);
              setIsScrolled(currentScrollPosition > 100 && currentScrollPosition < prevScrollPosition);
              setPrevScrollPosition(currentScrollPosition);
            }
          };
        
          useEffect(() => {
            if (scrollElement) {
              scrollElement.addEventListener('scroll', handleScroll);
            }
        
            return () => {
              if (scrollElement) {
                scrollElement.removeEventListener('scroll', handleScroll);
              }
            };
          }, [scrollElement, prevScrollPosition]);
        
          const setScrollRef = (ref) => {
            setScrollElement(ref);
          };
        const bombaso = dataPropiedadesUpdate.filter(searchingTerm(filtro)).map( x =>   (
                                 <div key={x.id} className="propiedades-box"
                                 onClick={ () => {
                                  setImg1(x.img[0].picture)
                                  setImg2(x.img[1].picture)
                                  setImg3(x.img[2].picture)
                                  setImg4(x.img[3].picture)
                                  setImg5(x.img[4].picture)
                                  setProp(x)
                                  WinAct()
                                  handleClick(x.id)
                              }}
                                 >
                                    <div className="box-img-propiedades" >
                                    <img className="img-float" src={like}/>
                                    <ManualImageSlider images={x.img} 
                                        
                                      
                                    
                                    />
                                    <div className="border-special">
                                      <div className={`dis-corregimiento `}>
                                            <h2> 
                                                {x.provincia} 
                                                <span className={`good-raya`}>{`|`}</span> 
                                                {x.distrito} 
                                                <span  className={`good-raya`}>{`|`}</span> 
                                                {x.corregimiento} 
                                            </h2>  

                                        </div>
                                    <div className={`dist-title-corregimiento`}>  
                                        <h4>{x.provincia}</h4> 
                                    </div>
                                    </div>
                                    </div>
                                  
                            <div className={` prop-text-prop  `} 
                                   
                            
                                    >
                           <span className="tittle-label-prop">
                           <h2>{x.label}</h2>
                           <div>  <img className="img-float-2" src={star}/> {x.puntuacion}</div>
                           </span>
                           <p className="normal-text">{x.text}</p>
                           <p className="normal-text">{x.ncamas} camas</p>
                           <span className="tittle-label-prop-foot">
                           <h2>${x.pornoche} noche</h2>
                           {/* <h3>${total} en total</h3> */}
                           </span>

                           </div>
                                   
                               
                                    </div> 
                               ))
                               useEffect( 
                                ()=>{
                                    setCantBusqueda(bombaso.length)
                            },[loeader])
    return (  
        <>      
                 
                <section className={`container-prop metal-trans ${loeader === false ? 'mascara-primo':'blurenia '}`} 
                ref={setScrollRef} 
                > 
                <section className={`propiedades ${bombaso.length !== 0 ? '' :''}`} >
                                 {bombaso}
                </section>
              </section>                  
        </>
    );
}
 
export default UltraPropiedades;