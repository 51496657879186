import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';


import App from './App';
import AppDomo from '../../jimf-codigo-madre-react/src/A-ELDOMO/App';
import AppBienesRaices from '../../jimf-codigo-madre-react/src/A-Bienes-Raices/App';
import HappyCappy from '../../jimf-codigo-madre-react/src/A-Happy-Cappy/src/screens/PagianWeb/PagianWeb'

import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";





///// domo styles /////
// import './stylos/style.css';
// import './stylos/dash.css';
// import './stylos/domo.css';
///// domo styles /////


///// bienes raices styles /////
import './A-Bienes-Raices/styles/bienes.css';
// import './stylos/style.css';
import './stylos/dash.css';
import './stylos/domo.css';
import './stylos/admin.css';
///// bienes raices styles /////

///// HappyCappy Styles //////
// import './stylos/happycappy.css';
// import './A-Happy-Cappy/src/screens/PagianWeb/style.css'
///// HappyCappy Styles //////



const root = ReactDOM.createRoot(document.getElementById('root'));

const changerapp = 3

root.render(
  
  <BrowserRouter>
  { changerapp === 1 &&
  
      <App /> 
  }

  { changerapp === 2 &&
      <AppDomo />  
  }  

{ changerapp === 3 &&
      <AppBienesRaices /> 
  }  

  { changerapp === 4 &&
      <>
      
      <HappyCappy />  
      </>
      
  }  

  </BrowserRouter>
  
);

  // console.log(process.env.REACT_APP_BACKEND_URL)
  // console.log(process.version)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

