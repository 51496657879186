// import React, { useState, useEffect, createContext } from 'react'
// import { useNavigate, useLocation } from 'react-router-dom';
// import clienteAxios from '../../config/axios';
// import axiosAuth from '../../config/axiosAuth';
// import {EN, ES} from '../../config/Language/Lenguage';
// import useData from '../../hooks/useData'

// const AuthContext = createContext();

// const AuthProvider = ({children}) => {
    
//     const [usuario, setUsuario] = useState({
//         email:'',
//         password:''
//     })
   
//     const [olvido, setOlvido] = useState(false);  
//     const togle = () => {
//         if(olvido === false) {
//             setOlvido(true)
//         }
//         if(olvido === true) {
//             setOlvido(false)
//         }
//     }
//     const { pathname } = useLocation();
//     const onChange = e => {
//         // voy a colocar guardar usuario en el state
//         setUsuario({
//             ...usuario,
//             [e.target.name] : e.target.value,
            
  
//         })
//     }
//     const Create = () => {
//         navigate('/create')
//     }
//     const Loggin = () => {
//         navigate('/core')
        
//     }
    

// const [lang, setLang] = useState({
//     data:ES,
//     status:'ES',

// });
// const ActiveEN = () => {
//     setLang({
//         data:EN,
//         status:'EN',
//     })
// }
// const ActiveES = () => {   
//     setLang({
//         data:ES,
//         status:'ES',
//     })
// }
// const [auth, setAuth ] = useState({
//     data:null,
//     status:false,
// })




// const navigate = useNavigate()
// const [ cargando, setCargando] = useState(false);
// const [ generalcargando, setGeneralLoad ] = useState(true);
// const token = localStorage.getItem('token');
// const [iniciarsesionstatus, setInStatus] = useState(false)
// const [Autenticado, setAutenticado] = useState(localStorage.getItem('Autenticado') || '');
// // const Autenticado = localStorage.getItem('Autenticado')
// const KillApp = () => {
//     setCargando(true)
//     // navigate('/');
//     localStorage.removeItem('token');
//     localStorage.removeItem('Autenticado');
//     setAuth({
//         data:null,
//         status:false,
//     })   
//     setGeneralLoad(false)
//     setCargando(false)
     
//                 setTimeout( () => {
//                     navigate('/');
//                 },2000)
    
    
   

// }


// // console.log(cargando)
// // console.log(auth)

// // console.log(` Cargando : ${cargando}`)
// // console.log(` iniciar sesion : ${iniciarsesionstatus}`)
// // console.log(` lo logramos`)
// ///// aqui se encuentra el recarga global





// // console.log(pathname)

// const autenticarUsuario = async () => {
//     // if(!token) {
//     //     setCargando(false)
//     // } 
    
//     try { 

//         const { data } = await clienteAxios.post('/api/client/usuario_autenticado',)
//         localStorage.setItem('Autenticado',data.message);
//         setAuth({
//             data:data.data,
//             status:true,
//         })
       
//         navigate('/core')
//         setCargando(false)
//     } catch (error) {
//         setAuth({
//             data:null,
//             status:false,
//         })   
//         navigate('/')
//         setCargando(false)
       
//     }
   
   
// }

// // useEffect(() => {
// //     const autenticarUsuario = async () => {
// //       try {

// //         if (!token) {
// //             // Si no hay sesión activa, redirige a la página de inicio de sesión
// //             navigate('/', { replace: true });
// //             return;
// //           }

// //         const { data } = await clienteAxios.post('/api/client/usuario_autenticado');
// //         localStorage.setItem('Autenticado', data.message);
// //         // Resto del código...
// //         navigate('/core', { replace: true });  // Redirige al usuario a la página principal después de iniciar sesión
// //       } catch (error) {
// //         // Manejar el error...
// //       }
// //     };

// //     autenticarUsuario();
// //   }, [navigate]);

// useEffect(
//     () => {
//         if ( pathname === '/' && auth.status === true ) {
            
//             navigate('/core')
//         } 
//     },[pathname]
    
// )

// useEffect(() => {
//     setCargando(true);
//     let isMounted = true;
//     if (isMounted && Autenticado !== '') {
//       autenticarUsuario();
//     }
//     return () => {
//       isMounted = false;
//     };
//     // setCargando(false)
//   }, [Autenticado]);
//   useEffect( () => {
//     if (generalcargando && auth.status === false ) {
//       setTimeout(
//           ()=> {
//             setGeneralLoad(false)
//           }, "1500");
//     }
//   },[!pathname] )


// //   useEffect(
// //     () => {
// //         if (generalcargando === true && auth.status === false ) {
// //             setGeneralLoad(false)
// //         }
// //     },[] );


//     return(
//         <AuthContext.Provider
//             value={{
//                 setAuth,
//                 auth,
//                 cargando,
//                 setInStatus,
//                 token,
//                 iniciarsesionstatus,
//                 lang,
//                 ActiveEN,
//                 ActiveES,
//                 KillApp,
//                 Autenticado,
//                 autenticarUsuario,
//                 generalcargando, setGeneralLoad
               



//             }}
        
//         >
//             {children}
//         </AuthContext.Provider>
//     )

// }

// export {
    
//     AuthProvider

// }

// export default AuthContext

import React, { useState, useEffect, createContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clienteAxios from '../../config/axios';
import { EN, ES } from '../../config/Language/Lenguage';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [usuario, setUsuario] = useState({ email: '', password: '' });
  const [olvido, setOlvido] = useState(false);
  const [lang, setLang] = useState({ data: ES, status: 'ES' });
  const [auth, setAuth] = useState({ data: null, status: false });
  const [cargando, setCargando] = useState(true);
  const [generalcargando, setGeneralLoad] = useState(true);
  const [iniciarsesionstatus, setInStatus] = useState(false);
  const [Autenticado, setAutenticado] = useState(localStorage.getItem('Autenticado') || '');

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Toggle between forgotten and normal login view
  const toggleOlvido = () => {
    setOlvido(prev => !prev);
  };

  // Handle user input changes
  const onChange = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  // Navigate to create route
  const Create = () => {
    navigate('/create');
  };

  // Navigate to core route
  const Loggin = () => {
    navigate('/core');
  };

  // Change language to English
  const ActiveEN = () => {
    setLang({ data: EN, status: 'EN' });
  };

  // Change language to Spanish
  const ActiveES = () => {
    setLang({ data: ES, status: 'ES' });
  };

  // Logout user
  const KillApp = () => {
    setCargando(true);
    localStorage.removeItem('token');
    localStorage.removeItem('Autenticado');
    setAuth({ data: null, status: false });
    setGeneralLoad(false);
    setTimeout(() => navigate('/'), 2000);
  };

  // Authenticate user
  const autenticarUsuario = async () => {
    setCargando(true);
    try {
      const { data } = await clienteAxios.post('/api/client/usuario_autenticado');
      localStorage.setItem('Autenticado', data.message);
      setAuth({ data: data.data, status: true });
      navigate('/core');
    } catch (error) {
      setAuth({ data: null, status: false });
      navigate('/');
    } finally {
      setCargando(false);
    }
  };

  // Check authentication status and redirect if needed
//   useEffect(() => {
//     if (pathname === '/' && auth.status) {
//       navigate('/core');
//     }
//   }, [auth.status, pathname, navigate]);

  // Load user authentication status
  useEffect(() => {
    if (Autenticado) {
      autenticarUsuario();
    } else {
      setCargando(false);
    }
  }, [Autenticado]);

  // Handle general loading state
  useEffect(() => {
    if (generalcargando && !auth.status) {
      setTimeout(() => setGeneralLoad(false), 1500);
    } else {
      setGeneralLoad(false);
    }
  }, [generalcargando, auth.status]);

  return (
    <AuthContext.Provider
      value={{
        setAuth,
        auth,
        cargando,
        setInStatus,
        token: localStorage.getItem('token'),
        iniciarsesionstatus,
        lang,
        ActiveEN,
        ActiveES,
        KillApp,
        Autenticado,
        autenticarUsuario,
        generalcargando,
        setGeneralLoad,
        usuario,
        onChange,
        olvido,
        toggleOlvido,
        Create,
        Loggin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
export default AuthContext;
