import React, { useState, createContext, useEffect} from 'react'
import clienteAxios from '../../config/axios'
import { Link, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth';







const DataRequestContext = createContext();


const DataRequestProvider = ({children}) => {
    const [loaderopinion, setLoaderOpinion] = useState(false)
    const OpenLoaderOpinion = () => {
        if(loaderopinion === false) {
            setLoaderOpinion(true)
        }
        if(loaderopinion === true) {
            setLoaderOpinion(false)
        }
    }
    const [navbar, setNavBar] = useState(true);
    const navBarto = () => {
        setNavBar(!navbar)
    }
    const [navbardos, setNavBarDos] = useState(true);
    const navBartoDos = () => {
        setNavBarDos(!navbardos)
    }
    const MostrarForos = async (token) => {
        if(!token) {
            setCargando(false)
        } 
        try {
            const { data } = await clienteAxios.post('/api/foro/list');
            setForos(data.data.reverse())
            // setProfile(data);
            // setCoreAdmin(data.user_type)
            // setCorporative(data.type_client)
            
            // if ( profile.user_type  === undefined )   {
            //     navigate('/')
            // }
        } catch (error) {
            setForos({})
            navigate('/')
        }
        setCargando(false)
    }
    /////////////////////// mostrar comunidades
    const [ comunidad, setComunidad] = useState([
        {
            created_at: "",
            created_by: null,   
            description: "",
            first_name: "",
            id: 0 ,
            last_name1: "",
            last_name2: "",
            middle_name: "",
            photo: null, 
            preferences: [],
            status: null,
            title: "Loading title",
            type: 1,
            updated_at: "",
        },
    ])
    const [comunidadheader, setComunH ]  = useState({
        title: "",
        description: "",
        id:0,
        preferences:[]
    })
    const [ comunidadobjeto, setComunidadObj] = useState(
        {
            created_at: "",
            created_by: null,   
            description: "",
            first_name: "",
            id: 0 ,
            last_name1: "",
            last_name2: "",
            middle_name: "",
            photo: null, 
            preferences: [],
            status: null,
            title: "Loading title",
            type: 1,
            updated_at: "",
        },
    )
    const [ bravo,  setBravo] = useState([
        {
            id	:0,
            user_id	:127,
            created_at:	"",
            updated_at:	"",
            title:"...loading",
            description:"...loading",
            estatus:	0,
            closed_at:	null,
            comunity_id: null,
        },
    ])    
    const MostrarComunidades = async (token) => {
        if(!token) {
            setCargando(false)
        } 
        try {
            const { data } = await clienteAxios.post('/api/comunity/list');
            setComunidad(data.data.reverse())
        } catch (error) {
            setComunidad({})
            navigate('/')
        }
        setCargando(false)
    }
    //////// aqui vamos a colocar el evento que agarra el crear foro ///
    const [createform, setCreateForum] = useState({
        title: "",
        description: "",
    })
    const AddForm = async datos => {
        setLoader(true)
        OpenVentana1()
        const token = localStorage.getItem('token')
        if(!token) {
            setCargando(false)
        }     
        try {
            const { data } = await clienteAxios.post('/api/foro/create',datos);  
            setWinAlert({
                data:data.message,
                status:true,
                open:true,
                })
                setCreateForum({
                    title: "",
                    description: "",
                })
                MostrarForos()
            setLoader(false)
        } catch (error) {
            setWinAlert({
                data:error.response.data.message,
                status:false,
                open:true,
                })
        }
        setLoader(false)
    } 
    const [creargrupocomunidad, setCrearGrupoComunidad ]  = useState({
        title: "",
        description: "",
        comunity_id:0
    }) 
    const CrearGrupoComunidadAction = async () => {
   setLoader(true)
   // setLoader(true)
   const token = localStorage.getItem('token')
   if(!token) {
       setCargando(false)
   } 

   try {
       const { data } = await clienteAxios.post('/api/group/create',creargrupocomunidad);
       MostrarGrupos()
       setWinAlert({
           data:data.message,
           status:true,
           open:true,
           })
           setLoader(false)
           OpenVentana4Closet()
   } catch (error) {
    //    setCrearComunidad({});
       setWinAlert({
           data:error.response.data.message,
           status:false,
           open:true,
           }) 
           OpenVentana4Closet()
    setLoader(false)
         }
    setCargando(false)
        }
    ///////// aqui vamos a coloar los modales ////
    const [ventanauno, setVentanaUno] = useState(false);
    const OpenVentana1 = () => {
        if(ventanauno === false) {
            setVentanaUno(true)
            
        }
        if(ventanauno === true) {
            setVentanaUno(false)
        }
    }
    
    const [foroheader, setForoH ]  = useState({
        title: "",
        description: "",
        id:0,
        
    })
    const [opinion, setOpinion] = useState({
        opinion:"",
        foro_id:0,
    })
    
    const AddOp = async () => {
        // setLoader(true)
        // const unioncomentario = {
        //     ...foroheader, ...opinion
        // }   
        setBlurLoad(true)
        const token = localStorage.getItem('token')
        if(!token) {
            setCargando(false)
        }     
        try {
            
            const { data } = await clienteAxios.post('/api/opinion/create',opinion);  
            setOpioLoad(true)
            setOpinion({
                ...opinion,
                opinion:"",
                
            })
            if (hidecoment === false) {
                Activa()
            }   
        } catch (error) {
        }
    }
    const [dataopiniones, setDataOpiniones] = useState([   
    ])
    const [dataopinionesres, setDataOpinionesRes] = useState([
        { id: 0, 
            comentador:'',
            opinion: ""
         }
    ])
    const [setid, setId] = useState({
        id:0,
    })
    const [opiload, setOpioLoad] = useState(false)
    const [ blurload , setBlurLoad] = useState(false)
    const [activate, setActivo] = useState(false)
    const MostrarOpiniones = async (token) => {  
       if ( opiload === true )
       {
        try {
            const { data } = await clienteAxios.post('/api/foro/info',setid);
     setDataOpiniones(data.foro.comentarios)
     setLoader(false)
     OpenVentana2()
     setBlurLoad(false)
        } catch (error) {
            // setForos({})
            navigate('/')
        }
       }
       setOpioLoad(false)
    }
    const [ventanados, setVentanaDos] = useState(false);
    const OpenVentana2 = () => {
            setVentanaDos(true)
            setActivo(true)
            
    }
    const OpenVentana2Closet = () => {
            setVentanaDos(false)
            setId([])
            setActivo(false)
            MostrarForos()      
    }
    const [datosusuario, setDatosUsuario ] = useState({
        first_name: "",
        middle_name: "",
        last_name1:"",
        last_name2: "",
        identity:"",
        id_type:"",
        birthday_date:"",
        nationality:"",
        id_expiration_date:"",
        place_birth:"",
        emision_country:"",
        marital_status:"",
        gender:"",
        education_level:"",
        ocupation:"",
        place_work:"",
        job_adress:"",
        annual_salary:"",
        type_bussiness:"",
        job_title:"",
        source_worth:"",
        pep:"",
        married_name:"",
        dependents_nro:'',
        gender:''
        })
        const [crearcomunidad, setCrearComunidad] = useState({
            title:"",
            description:"",
            type:1,
            categorias:[]
        })
    const [ventanatres, setVentanaTres] = useState(false);
    const OpenVentana3 = () => {
        if(ventanatres === false) {
            setDatosUsuario(profile)
            setVentanaTres(true)
        }
        if(ventanatres === true) {
            setVentanaTres(false)
        }
    }
    const [ventanacuatro, setVentanaCuatro] = useState(false);
    const OpenVentana4 = () => {
        setVentanaCuatro(true)
     
    }
    const OpenVentana4Closet = () => {
        setVentanaCuatro(false)
        }
    const [ventanacinco, setVentanaCinco] = useState(false);
    const OpenVentana5 = () => {
        if(ventanacinco === false) {
            setVentanaCinco(true)
        }
        if(ventanacinco === true) {
            setVentanaCinco(false)
        }
    }
    const [ventanaseis, setVentanaSeis] = useState(false);
    const OpenVentana6 = () => {
            setVentanaSeis(true)
    }
    const OpenVentana6Closet = () => {
            setVentanaSeis(false)
    }
    ///////////
    const [ foros, setForos] = useState([
        {
            id	:0,
            user_id	:127,
            created_at:	"",
            updated_at:	"",
            title:"...loading",
            description:"...loading",
            estatus:	0,
            closed_at:	null,
            opiniones:[
                // { id: 0, 
                //     comentador:'',
                //     opinion: ""
                //  }
            ]
        },
    ])
    ////// admin /////
    const [admin001, setAd001] = useState({})
    const [admin002, setAd002] = useState({})
    //// aqui vamos a colocar el estado del modal ID , para sacar el pdf
    const [modalid, setModalId] = useState('')
    //////
    const [modalidacc, setModalIdAcc] = useState('')

    const [recargardata, setRecargar] = useState(false);

    const Recargar = () => {
        if(recargardata === true) {
            setRecargar(false)
        } 
        if(recargardata === false) {
            setRecargar(true)
        }
    }
    const [windowalerta, setWinAlert] = useState({
        data:null,
        status:true,
        open:false,
    })
    const [alertamaster, setAlertaMaster] = useState({
        data:null,
        status:false,
    })
    //// aqui vamos a colocar el estado de la navegacion ////
    const [burgernav, setBurgerNav] = useState(false);
    const ActionBurger = () => {
        if (burgernav === true){
            setBurgerNav(false)
        }
        if (burgernav === false ) {
            setBurgerNav(true)
        }
    }
    //// aqui vamos a colocar el estado del loader ////
    const navigate = useNavigate();
    const [Loader, setLoader ] = useState(false);
    ///// aqui colocamos el estado de legal o persona natural
    const [corporative, setCorporative] = useState('');
    ///// luego colocamos el estado para cambiar de admin
    const [coreadmin, setCoreAdmin] = useState(0);
    /////////////////////////////////////////////////////////
    /// opciones, aqui vamos a cambiar password///
    const [changepassword, setChangePass] = useState({
        current_password:'',
        new_password:'',
        confirm_password:'',
    })

    /// opciones, aqui vamos a cambiar password///
    const ChangePassAction = async () => {
        const token = localStorage.getItem('token')
        // if(!token) {
        //     setCargando(false)
        // } 
        OpenVentana3()
        setLoader(true)
        try {
            const { data } = await clienteAxios.post('/api/client/changepassword',changepassword);
            setWinAlert({
                data:data.message,
                status:true,
                open:true,
                })
                setChangePass({
                    current_password:'',
                    new_password:'',
                    confirm_password:'',
                })
                MostrarProfile();
                setLoader(false)
        } catch (error) {
            setLoader(false)
            setWinAlert({
                data:error.response.data.message,
                status:false,
                open:true,
                })
        }
        setCargando(false)   
    }
 let defaultstateprofile2 = {
    id:0,
    created_at:"",
    updated_at:"",
    user_id:0,
    currency_tag:null,
    account_type:null,
    account_model:null,
    initial_deposit:"",
    method_inicial_deposit:null,
    purpose_account:null,
    source_fund_initial:null,
    source_fund_recurring:null,
    source_wealth:null,
    incomig_cash_deposit_amount:"",
    incomig_cash_deposit_quantity:0,
    incomig_check_deposit_amount:"0.00",
    incomig_check_deposit_quantity:0,
    incomig_transfer_amount:"0.00",
    incomig_transfer_quantity:0,
    incomig_ach_amount:"0.00",
    incomig_ach_quantity:0,
    incomig_internal_amount:"0.00",
    incomig_internal_quantity:0,
    outgoing_cash_withdrawall_amount:"0.00",
    outgoing_cash_withdrawall_quantity:0,
    outgoing_check_amount:"0.00",
    outgoing_check_quantity:0,
    outgoing_transfer_amount:"0.00",
    outgoing_transfers_quantity:0,
    outgoing_ach_amount:"0.00",
    outgoing_ach_quantity:0,
    outgoing_internal_amount:"0.00",
    outgoing_internal_quantity:0,
    beneficiario_transfer_name1:null,
    beneficiario_transfer_country1:null,
    beneficiario_transfer_name2:null,
    beneficiario_transfer_country2:null,
    beneficiario_transfer_name3:null,
    beneficiario_transfer_country3:null,
    beneficiario_transfer_name4:null,
    beneficiario_transfer_country4:null,
    remitter_fund_name1:null,
    remitter_fund_country1:null,
    remitter_fund_name2:null,
    remitter_fund_country2:null,
    remitter_fund_name3:null,
    remitter_fund_country3:null,
    remitter_fund_name4:null,
    remitter_fund_country4:null,
    signatured_name1:null,
    signatured_id1:null,
    signatured_file1:null,
    signatured_name2:null,
    signatured_id2:null,
    signatured_file2:null,
    signatured_name3:null,
    signatured_id3:null,
    signatured_file3:null,
    signatured_name4:null,
    signatured_id4:null,
    signatured_file4:null}
    let defaultstatebtn = [
        {
        id: '',
        user_id: '',
        created_at: '',
        updated_at: '',
        step: '',
        state: "hide"
        },
        {
            id: '',
            user_id: '',
            created_at: '',
            updated_at: '',
            step: '',
            state: "hide"
            },{
                id: '',
                user_id: '',
                created_at: '',
                updated_at: '',
                step: '',
                state: "hide"
                },{
                    id: '',
                    user_id: '',
                    created_at: '',
                    updated_at: '',
                    step: '',
                    state: "hide"
                    },{
                        id: '',
                        user_id: '',
                        created_at: '',
                        updated_at: '',
                        step: '',
                        state: "hide"
                        }           
                    ]

        



    const {auth} = useAuth()    
    // este es el estado para los botones del menu ///
    const [megaBtn, setMegaBtn] = useState(defaultstatebtn);
    //// este esado es para actulizar datos /////
    const [actulizardatos, setActualizarDatos] = useState({});
    const [actulizardatosacc, setActualizarDatosAcc] = useState({});

    ///// este estado es para mostrar datos del perfil ///
    const [profile, setProfile] = useState({});

    

    ///////////////////////////////////// *****************
    const [profile2, setProfile2] = useState(defaultstateprofile2);
    //// en este estado vamos a colocar la lista de beneficiarios
    const [ beneficiarios, setBen ] = useState({});
    const [beneficiarioespecial, setBenS] = useState({});
                            ///// aqui la lista de beneficiario ////
    
    //////////////////////
        /////////////////// admin data ////
    const [listaclientes, setListaClientes ] = useState([]);
    const [dasboardadmin, setAdminDashData] = useState({})

    const MostrarClientes = async (token) => {

            if(!token) {
                setCargando(false);
            }
            try{
                const {data} = await clienteAxios.post('/api/client/list');
                setListaClientes(data.data.clients)
                setAdminDashData(data.data.grupos);
            } catch (error){
                navigate('/')
            }
    }
    const [lugarfirmante, setLugarFirmante] = useState(1);
    const [firmante, setFirmante] = useState({});
    const [firmante2, setFirmante2] = useState({});
    const [firmante3, setFirmante3] = useState({});
    const [firmante4, setFirmante4] = useState({});
    const [individual, setIndividual ] = useState([]);
    const [ cargando, setCargando] = useState(true);
    //////  aqui vamos a colocar la funcion de iniciar secion y las alertas
    //// api para saber en que paso esta el usuario //////
    // const token = localStorage.getItem('token')
    const superData = async (token) => {
        if(!token) {
            setCargando(false)
        } 
        try {
            const { data } = await clienteAxios.post('/api/client/getsteps',);
            setMegaBtn(data.steps);
        } catch (error) {
            navigate('/')
        }
        setCargando(false)
    }
    const MostrarBeneficiario = async (token) => {
        
        if(!token) {
            setCargando(false)
        }
        try {
            const { data } = await clienteAxios.post('/api/client/beneficiarios',);
            setBen(data.data)
            
        } catch (error ){
            navigate('/')
        }
    } 
    const MostrarBeneficiarioEspecial = async (token) => {
        const type = {
            type:2
        }
        if(!token) {
            setCargando(false)
        }
        try {
            const { data } = await clienteAxios.post('/api/client/beneficiarios',type);
            setBenS(data.data[0])
            
        } catch (error ){
            navigate('/')
        }
    }   
    const [categoriasshow, setCat] = useState([
        {id:0,
        created_at:"",
        updated_at:"",
        name:"Loading...",
        estatus:null
        }
    ])
    const MostrarCat = async (token) => {
        if(!token) {
            setCargando(false)
        } 
        try {
            const { data } = await clienteAxios.post('/api/client/categorias');   
            setCat(data.data)
            // if ( profile.user_type  === undefined )   {
            //     navigate('/')
            // }
        } catch (error) {
            navigate('/')
        }
        setCargando(false)
    } 
    const MostrarProfile = async (token) => {
        MostrarCat()
        if(!token) {
            setCargando(false)
        } 
        try {
            const { data } = await clienteAxios.post('/api/client/profile');
           
            setProfile(data);
            setDatosUsuario(data)
            
            setCoreAdmin(data.user_type)
            setCorporative(data.type_client)
            // if ( profile.user_type  === undefined )   {
            //     navigate('/')
            // }
        } catch (error) {
            setProfile({});
            navigate('/')
            
        }

        setCargando(false)

        
    } 


    


    ///// aqui estamos colocando para mostrar los datos de los firmante /////
    
    const MostrarFirmantes = async (token) => {
        if(!token) {
            setCargando(false)
        } 
        try {
            const { data } = await clienteAxios.post('/api/client/firmante1');
            setFirmante(data);

        } catch (error) {
            setFirmante({});
            navigate('/')
            
        }

        setCargando(false)
    }

    const MostrarFirmantesDos = async (token) => {
        if(!token) {
            setCargando(false)
        } 

        try {
            
            const { data} = await clienteAxios.post('/api/client/firmante2');
            setFirmante2(data);

        } catch (error) {
            setFirmante2({});
            navigate('/')
            
        }

        setCargando(false)
    }

    const MostrarFirmantesTres = async (token) => {
        if(!token) {
            setCargando(false)
        } 
        try {  
            const { data } = await clienteAxios.post('/api/client/firmante3');
            setFirmante3(data);

        } catch (error) {
            setFirmante3({});
            navigate('/')
        }
        setCargando(false)
    }

    const MostrarFirmantesCuatro = async (token) => {
        if(!token) {
            setCargando(false)
        }
        try {
            const { data } = await clienteAxios.post('/api/client/firmante4');
            setFirmante4(data);

        } catch (error) {
            setFirmante4({});
            navigate('/')
            
        }
        setCargando(false)
    }

    ////////////////////////////// aqui es para mostrar los datos del firmante //////
    

    //// estado par arecibir las respuesta de//////

    const [respuestafirmante , setResFirmante] = useState({})
    const FirmanteAction = async datos => {
        const token = localStorage.getItem('token')
        if(!token) {
            setCargando(false)
        } 

        try {
            const { data } = await clienteAxios.post('/api/client/update_firmante1',datos);
            setResFirmante(data);
            setWinAlert({
                data:data,
                status:true,
                open:true,
                })
            MostrarFirmantes();
        } catch (error) {
            setResFirmante({});
        }

        setCargando(false)

        
    }

    
    const [respuestafirmantedos , setResFirmanteDos] = useState({})
    const FirmanteActionDos = async datos => {
        setLoader(true)
        const token = localStorage.getItem('token')
        if(!token) {
            setCargando(false)
        } 

        try {
            const { data } = await clienteAxios.post('/api/client/update_firmante2',datos);
            setResFirmanteDos(data);
            MostrarFirmantesDos();
            setWinAlert({
                data:data,
                status:true,
                open:true,
                })
            setLoader(false)
        } catch (error) {
            setResFirmanteDos({});
            setLoader(false)
        }

        setCargando(false)

        
    }

    
    const [respuestafirmantetres , setResFirmanteTres] = useState({})
    const FirmanteActionTres = async datos => {
        setLoader(true)
        const token = localStorage.getItem('token')
        if(!token) {
            setCargando(false)
        } 
        try {
            const { data } = await clienteAxios.post('/api/client/update_firmante3',datos);
            setResFirmanteTres(data);
            MostrarFirmantesTres();
            setWinAlert({
                data:data,
                status:true,
                open:true,
                })

            
            setLoader(false)
        } catch (error) {
            setResFirmanteTres({});
        }
        setCargando(false)

        
    }

    
    const [respuestafirmantecuatro , setResFirmanteCuatro] = useState({})

    const FirmanteActionCuatro = async datos => {
        setLoader(true)
        const token = localStorage.getItem('token')
        if(!token) {
            setCargando(false)
        } 
        try {
            const { data } = await clienteAxios.post('/api/client/update_firmante4',datos);
            setResFirmanteCuatro(data);
            MostrarFirmantesCuatro();
            setWinAlert({
                data:data,
                status:true,
                open:true,
                })

            //// loader & alerta ///
            setLoader(false);
            
            //// loader & alerta ///

        } catch (error) {
            setResFirmanteCuatro({});
            setLoader(false)
        }
        setCargando(false)
    }
    /////// aui vamos a terminar el estado de las respuestas
    const MostrarAcc = async (token) => {
        if(!token) {
            setCargando(false)
        } 

        try {
            const { data } = await clienteAxios.post('/api/account/info');
            if (data.account === null) {
                setProfile2(defaultstateprofile2);
            } else {
                setProfile2(data.account);
            }

            
            

        } catch (error) {
            setProfile2({});
            navigate('/')
            
        }

        setCargando(false)

        
    }
    const [individualblankfirmante, setIndividualBlankFirmante ] = useState({
        first_name: "",
        type_client:"",
        middle_name: "",
        last_name1:"",
        last_name2: "",
        identity:"",
        id_type:"",
        birthday_date:"",
        nationality:"",
        id_expiration_date:"",
        place_birth:"",
        emision_country:"",
        marital_status:"",
        gender:"",
        education_level:"",
        ocupation:"",
        place_work:"",
        job_adress:"",
        annual_salary:"",
        type_bussiness:"",
        job_title:"",
        source_worth:"",
        pep:"",
        married_name:"",
        dependents_nro:'',
        gender:''
        })    
    const [individualblank, setIndividualBlank ] = useState({
        first_name: "",
        middle_name: "",
        last_name1:"",
        last_name2: "",
        identity:"",
        id_type:"",
        birthday_date:"",
        nationality:"",
        id_expiration_date:"",
        place_birth:"",
        emision_country:"",
        marital_status:"",
        gender:"",
        education_level:"",
        ocupation:"",
        place_work:"",
        job_adress:"",
        annual_salary:"",
        type_bussiness:"",
        job_title:"",
        source_worth:"",
        pep:"",
        married_name:"",
        dependents_nro:'',
        gender:''
        })

        

      const [accountblank, setAccBlank]   = useState({
        id: "",
        created_at: "",
        updated_at: "",
        user_id: "",
        currency_tag: "",
        account_type: "",
        account_model: "",
        initial_deposit: "",
        method_inicial_deposit: "",
        purpose_account: "",
        source_fund_initial: "",
        source_fund_recurring: "",
        source_wealth: "",
        incomig_cash_deposit_amount: "",
        incomig_cash_deposit_quantity: "",
        incomig_check_deposit_amount: "",
        incomig_check_deposit_quantity: "",
        incomig_transfer_amount: "",
        incomig_transfer_quantity: "",
        incomig_ach_amount: "",
        incomig_ach_quantity: "",
        incomig_internal_amount: "",
        incomig_internal_quantity: "",
        outgoing_cash_withdrawall_amount: "",
        outgoing_cash_withdrawall_quantity: "",
        outgoing_check_amount: "",
        outgoing_check_quantity: "",
        outgoing_transfer_amount: "",
        outgoing_transfers_quantity: "",
        outgoing_ach_amount: "",
        outgoing_ach_quantity: "",
        outgoing_internal_amount: "",
        outgoing_internal_quantity: "",
        beneficiario_transfer_name1: "",
        beneficiario_transfer_country1: "",
        beneficiario_transfer_name2: "",
        beneficiario_transfer_country2: "",
        beneficiario_transfer_name3: "",
        beneficiario_transfer_country3: "",
        beneficiario_transfer_name4: "",
        beneficiario_transfer_country4: "",
        remitter_fund_name1: "",
        remitter_fund_country1: "",
        remitter_fund_name2: "",
        remitter_fund_country2: "",
        remitter_fund_name3: "",
        remitter_fund_country3: "",
        remitter_fund_name4: "",
        remitter_fund_country4: "",
        signatured_name1: "",
        signatured_id1: "",
        signatured_file1: "",
        signatured_name2: "",
        signatured_id2: "",
        signatured_file2: "",
        signatured_name3: "",
        signatured_id3: "",
        signatured_file3: "",
        signatured_name4: "",
        signatured_id4: "",
        signatured_file4: "",
      })

        const [ specialben, setSpecialBen] = useState({
            user_id:"",
            phone:"",
            address:"",
            name:"",
            email:"",
            fecha_nacimiento:"",
        })

        const CrearBenActionEspecial = async () => {

            const type = {
                type:2
            } 

            const crearelben = {...specialben, ...type }
            

            setLoader(true);
            const token = localStorage.getItem('token')
            if(!token) {
                setCargando(false)
            } 
            try {
                const { data } = await clienteAxios.post('/api/client/beneficiario_create',crearelben);

                setWinAlert({
                    data:data.message,
                    status:true,
                    open:true,
                    })
                // setBen(data);
                MostrarBeneficiarioEspecial();
                
                setSpecialBen({
                    user_id:"",
                    phone:"",
                    address:"",
                    name:"",
                    email:"",
                    fecha_nacimiento:"",
                    })
                    setLoader(false)
            } catch (error) {
                setWinAlert({
                    data:error.response.data.message,
                    status:false,
                    open:true,
                })
                setLoader(false);
                
            }
            setCargando(false)
        }


        const [createben, setCreateBen ] = useState({
            user_id:"",
            phone:"",
            address:"",
            name:"",
            email:"",
            percentage:"",
            fecha_nacimiento:""
            })

        const CrearBenAction = async (mostrarlista, setMostrarLista, createben) => {
            setLoader(true);
            const token = localStorage.getItem('token')
            if(!token) {
                setCargando(false)
            } 
            try {
                const { data } = await clienteAxios.post('/api/client/beneficiario_create',createben);

                setWinAlert({
                    data:data.message,
                    status:true,
                    open:true,
                    })
                
                // setBen(data);
                MostrarBeneficiario();
                setMostrarLista(true);
                setCreateBen({
                    user_id:"",
                    phone:"",
                    address:"",
                    name:"",
                    email:"",
                    percentage:"",
                    fecha_nacimiento:""
                    
                    })
                    setLoader(false)
            } catch (error) {
                setCreateBen({
                    user_id:"",
                    phone:"",
                    address:"",
                    name:"",
                    email:"",
                    percentage:"",
                    fecha_nacimiento:""    
                    })
                
            }
    
            setCargando(false)
            setMostrarLista(true);
    
            
        }

    
        const EliminarBenAction = async (id) => {
            setLoader(true)
            const token = localStorage.getItem('token')
            if(!token) {
                setCargando(false)
            } 

            try {
                const { data } = await clienteAxios.post(`/api/client/beneficiario_delete/${id}`);
                setWinAlert({
                    data:data.message,
                    status:true,
                    open:true,
                    })
                
                // setBen(data);
                MostrarBeneficiario();
                setLoader(false)
                
            } catch (error) {
                return
                
            }
    

        }

        const CrearComunidadAction = async carga => {
             OpenVentana5()
            setLoader(true)
            // setLoader(true)
            const token = localStorage.getItem('token')
            if(!token) {
                setCargando(false)
            } 
    
            try {
                const { data } = await clienteAxios.post('/api/comunity/create',carga);
                MostrarComunidades()
                setCrearComunidad({});
                setWinAlert({
                    data:data.message,
                    status:true,
                    open:true,
                    })
                    setLoader(false)
                
                
            } catch (error) {
                setCrearComunidad({});

                
                setWinAlert({
                    data:error.response.data.message,
                    status:false,
                    open:true,
                    })
                
          setLoader(false)
                
                
            }
    
            setCargando(false)
    
            
        }

        const ActualizarDatosAction = async () => {
            OpenVentana3()
            setLoader(true)
            // setLoader(true)
            const token = localStorage.getItem('token')
            if(!token) {
                setCargando(false)
            } 
    
            try {
                const { data } = await clienteAxios.post('/api/client/update',datosusuario);
                MostrarProfile();     
                setWinAlert({
                    data:data.message,
                    status:true,
                    open:true,
                    })
                    setLoader(false)
                
                // setActualizarDatos(data);
                           
                
            //// loader & alerta ///
            // setLoader(false);
            // setAlertaMaster({
            //     data:null,
            //     status:false,
                
            // })
            
            //// loader & alerta ///
                
            } catch (error) {
                setActualizarDatos({});

                
                setWinAlert({
                    data:error.response.data.message,
                    status:false,
                    open:true,
                    })
                
          setLoader(false)
                
                
            }
    
            setCargando(false)
    
            
        }

        const ActualizarAccountAction = async datos => {
            setLoader(true)
            const token = localStorage.getItem('token')
            if(!token) {
                setCargando(false)
            }     
            try {
                const { data } = await clienteAxios.post('/api/account/create',datos);  
                setActualizarDatosAcc(data);
                MostrarAcc();
                setWinAlert({
                    data:data.message,
                    status:true,
                    open:true,
                    })

                setLoader(false)
                
            } catch (error) {
                // setActualizarDatosAcc({});
                
                setWinAlert({
                    data:error.response.data.message,
                    status:false,
                    open:true,
                    })
            }
            setLoader(false)
        }

        const [setsupra, setSup] = useState(false)
        

        const [hidecoment, setHideC] = useState(false)
         const Activa = () => {
            if (hidecoment === false) {  
                setHideC(true)  }

            if(hidecoment === true) {
                setHideC(false)
            }
         }


         const [opiloaddos, setOpioLoadDos] = useState(false)


     
         const [comunityid, setComunityId]  = useState({
            comunity_id:0,
        })
        
    
      
         const MostrarGrupos = async (token) => {
        
            if ( opiloaddos === true )
            {
             try {
                 const { data } = await clienteAxios.post('/api/group/list',comunityid);
                 
                 setBravo(data.data.reverse())
            //  //  setDataOpiniones(data.foro.comentarios.reverse())
         
       
        
             } catch (error) {
                 // setForos({})
                 navigate('/')
             }
            }
            setOpioLoadDos(false)
           
         } 


    // useEffect(
    //     () => {         
    //                         if (alertamaster.status === false) {
    //                             setTimeout( ()=> {
                                    
    //                                 setAlertaMaster({
    //                                     data:null,
    //                                     status:false,
                                        
    //                                 })
    //                             },5000)

                                
    //                         }
                            
    //     },[alertamaster]
    // )

    useEffect( ()=> {
        MostrarOpiniones()
    },[opiload])

    
    useEffect( ()=> {
        MostrarGrupos()
    },[opiloaddos])


    return (


        <DataRequestContext.Provider
            value={{

                megaBtn,
                superData,
                actulizardatos,
                actulizardatosacc,
                setActualizarDatos,

                ActualizarDatosAction,
                ActualizarAccountAction,

                CrearBenAction,
                MostrarProfile,
                dasboardadmin,



                createben,
                setCreateBen,

                profile,
                profile2,

                individualblank,
                setIndividualBlank,
                MostrarBeneficiario,
                
                individualblankfirmante,
                setIndividualBlankFirmante,
                
                beneficiarios,
                EliminarBenAction,
                Loader,
                MostrarAcc,

                ////// aqui voy a colocar el segundo formulario para accionar ////
                accountblank, 
                setAccBlank,
                firmante,
                firmante2,
                firmante3,
                firmante4,
                ////// aqui voy a colocar el segundo formulario para accionar ////


                //////// aqui vamos a colocar los firmantes ////
                MostrarFirmantes,
                MostrarFirmantesDos,
                MostrarFirmantesTres,
                MostrarFirmantesCuatro,
                ////

                lugarfirmante,
                setLugarFirmante,
                respuestafirmante,
                FirmanteAction,
                respuestafirmantedos,
                FirmanteActionDos,
                respuestafirmantetres,
                FirmanteActionTres,
                respuestafirmantecuatro,
                FirmanteActionCuatro,

                alertamaster,

                corporative, 
                setCorporative,
                
                changepassword, 
                setChangePass,


                burgernav,
                ActionBurger,

                coreadmin,
                setCoreAdmin,
                
                listaclientes,
                MostrarClientes,

                modalid,
                setModalId,

                modalidacc, 
                setModalIdAcc,
                windowalerta,
                setWinAlert,
                Recargar,
                recargardata,

                specialben, 
                setSpecialBen, 
                CrearBenActionEspecial,
                MostrarBeneficiarioEspecial,
                beneficiarioespecial,

                admin001,
                setAd001,
                admin002,
                setAd002,
                ////////////////////////////////// el domo //////

                MostrarForos,
                foros ,
                ventanauno,
                OpenVentana1,
                ventanados,
                OpenVentana2,
                ventanatres,
                OpenVentana3,
                ventanacuatro,
                OpenVentana4,
                ventanacinco,
                OpenVentana5,
                ventanaseis,
                OpenVentana6,
                OpenVentana6Closet,


                OpenVentana4Closet,

                ////////////////////

                createform,
                setCreateForum,
                AddForm,

                foroheader, 
                setForoH,
                AddOp,
                setOpinion,
                opinion,
                MostrarOpiniones,
                datosusuario, 
                setDatosUsuario,

                dataopiniones,
                 setDataOpiniones,
                 loaderopinion,
                 setLoaderOpinion,
                 setId,

                 dataopinionesres, 
                 setDataOpinionesRes,
                 setsupra, setSup,
                 setVentanaDos,
                 OpenVentana2Closet,
                 OpenLoaderOpinion,
                 opiload, setOpioLoad,
                 blurload , setBlurLoad,
                 Activa,
                 hidecoment,
                 setLoader,
                 activate, setActivo,
                 categoriasshow, setCat,
                 navbar, navBarto,
                 navBartoDos,navbardos,
                 ChangePassAction ,
                 comunidad, 
                 MostrarComunidades ,
                 comunidadheader, setComunH,
                 crearcomunidad, setCrearComunidad,
                 CrearComunidadAction,
                 creargrupocomunidad, setCrearGrupoComunidad,
                 CrearGrupoComunidadAction,
                 bravo,  setBravo
                 ,opiloaddos, setOpioLoadDos,
                 comunidadobjeto, setComunidadObj,
                 setComunityId
             

            }}
        
        >


            {children}
        </DataRequestContext.Provider>

    )


}

export {
    DataRequestProvider
}

export default DataRequestContext;
