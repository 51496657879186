import React, { useEffect, useState, lazy, Suspense, useRef } from 'react';
import HeaderBar from './DashBoardUltimate/HeaderBar';
import FooterBar from './DashBoardUltimate/FooterBar';
import Propiedades from './DashBoardUltimate/Propiedades';
import UltraPropiedades from './DashBoardUltimate/UltraPropiedades';
import useAct from '../hooks/useAct';
import HeaderBarPublico from './DashBoardUltimate/HeaderBarPublico';
const LazyPropiedades = lazy(() => import('./DashBoardUltimate/Propiedades'));

const UltraUsuarioPublico = () => {


    ///// aqui esta la interfase separada ///

  

    const {
        open,
        toggle,
        setO,
        windowscloset
    } = useAct()
 

    return (  

            <>
           

            <section className={`App-container-joema ${windowscloset === true ? '' : ''} `}
                
            onClick={
                () => {
                    if (open === true) {
                        setO(false)
                    }
                }

            }>
                 <HeaderBarPublico/>

                 <section
                    className={` ${windowscloset === false ? '' : 'nover'} `}
                 >
                 <UltraPropiedades/>
                 </section>
               
                    
                  
{/*                     
                 <div className="scrollmasive">

</div> */}

            
               <FooterBar/>
            </section>
              

            </>

    );
}
 
export default UltraUsuarioPublico;