import React, { useState, useEffect } from 'react'
import useAct from '../../hooks/useAct';
import key from '../../imagenes/modalicon/key.svg'
import pets from '../../imagenes/modalicon/pets.svg'
import auto from '../../imagenes/modalicon/auto.svg'
import chat from '../../imagenes/modalicon/chat.svg'
import wifi from '../../imagenes/modalicon/wifi.svg'
import vista from '../../imagenes/modalicon/vista.svg'
import FooterBar from './FooterBar';
import img1x from '../../imagenes/iconosacc/datos.svg'
import img2x from '../../imagenes/iconosacc/inicio.svg'
import img3x from '../../imagenes/iconosacc/x3.svg'
import img4x from '../../imagenes/iconosacc/x4.svg'
import img5x from '../../imagenes/iconosacc/x5.svg'
import img6x from '../../imagenes/iconosacc/x6.svg'
import img7x from '../../imagenes/iconosacc/x7.svg'
import img8x from '../../imagenes/iconosacc/x8.svg'
import img9x from '../../imagenes/iconosacc/x9.svg'
import img10x from '../../imagenes/iconosacc/x10.svg'
import allmensaje from '../../imagenes/iconos/allmensaje.svg'
import x from '../../imagenes/iconos/x.svg'
import InputDynamic from '../../componentes/InputD/InputDynamic';

/// la barra copn sus cosas


import h from '../../imagenes/iconos/heartilljoe.svg'
import m from '../../imagenes/iconos/Mem.svg'
import xx from '../../imagenes/iconosacc/x3.svg'
import xxx from '../../imagenes/iconos/Swhite.svg'
import B from '../../imagenes/iconos/WorldAir.svg'

import useData from '../../hooks/useData';

const WindowAddProp = () => {

  

    const {
        win,
        WinAct,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5,
        winfavoritos, WinFav,
        winprop,WinProp,
        WinIdioma
    } = useAct()
    const onChange = e => {
        // voy a colocar guardar usuario en el state
        // setFocusSelect({
        //      ...focusselect,
        //     [e.target.name] : e.target.value
    
        // })
        // setFocus('')
        setDatosUsuario({
            ...datosusuario,
            [e.target.name] : e.target.value,
        })
    }
    



    const {
        datosusuario,
        focusselect , setFocusSelect,
        profile,
        ActualizarDatosAction,
        setDatosUsuario
    } = useData()

    const [profileactive, setProfileAct] = useState(false);
    const [estadoselection, stSelect] = useState(0);
    const StateZona = () => {
        stSelect(0)
    }
    const PriZona = () => {
        stSelect(1)
    }
    const SecZona = () => {
        stSelect(2)
    }
    const TreZona = () => {
        stSelect(3)
    } 

    /////  aqui vamos a coloar el estado del detalles /////

    const [detalles, setDetalle] = useState(true);
    const ActDetalles = () => {
        setDetalle(!detalles)
    }
    useEffect(
        () => {         
            if(detalles === false) {
                SecZona()
            }
            if(detalles === true) {
                TreZona()
            }
                                
        },[detalles]
    )

    

    return ( 
            <>
          {winprop === true ?
            <> 
                  <div  className={` windows-selection ${winprop !== false ? ' op-true adelante willon  ':''}  ${winprop !== true ? 'animation-open-option-close  op-none':''}`}>
                <section className="yeah-scroll">
                <section className="bar-bar-joema" 
                   
                    > 

                        <section className={`Bar-bar-joema-float`}>
                        <p>   GroundScript   </p>   
                        </section>

                    </section>
                    <section className="box-information">   
                    
                    <section className={`arc d-none ${detalles === false ? 'arc-closet ' : ''}`}>
                                <section 
                                    className={` 
                                    mensaje-1
                                    `}
                                    onClick={
                                        estadoselection !== 1 ? PriZona : StateZona
                                    }
                                >       
                            <div className={`hijo-hijo-hijo
                                        ${estadoselection === 1 ? 'opacity-true' : ''}
                                    `}>
                                        <span className="hijo-center">
                                        </span>
                                        <span className="hijo-left">
                                        </span>
                                    </div>
                                    <section className="box-cloud">
                                       <div className="box-cloud-def">
                                       <p>Mensajes</p>
                                        <div className="img-arc">
                                        <img src={allmensaje}/>
                                        </div>
                                       </div>
                                    </section>

                                    <section className="box-cloud-body">

                                    <div className="box-cloud-def-body">

                                        <h3>No tienes mensajes sin leer</h3>
                                        <p>Cuando reserves un viaje o una experiencia, los mensajes de tu anfitrión aparecerán aquí.</p>

                                        <button className="btn-bienes">
                                            Explorar GroundScript
                                        </button>

                                        </div>
                                    </section>



                                </section>


                                <section className={`mensaje-expand`}>


                                <section
                                        className={` 
                                        mensaje-2
                                      
                                        ${detalles === false ? '  width-true-true' : ''}
                                        `}
                                        onClick={
                                            estadoselection !== 2 ? SecZona : StateZona
                                        }

                                >

                                    <div className={`hijo-hijo-hijo
                                        ${estadoselection === 2 ? 'opacity-true' : ''}
                                    `}>

                                        <span className="hijo-left">

                                        </span>

                                        <span className="hijo-center">

                                        </span>
                                        <span className="hijo-right">

                                        </span>
                                    </div>

                                <section className="box-cloud"
                                     onClick={

                                        () => {
                                            ActDetalles()
                                          

                                        }

                                     }
                                >

                                   

                                    </section>

                                    
                                </section>

                                
                                <section 
                                    className={` 
                                    mensaje-3
                                    ${detalles === false ? 'width-false' : ''}
                                    
                                    `}
                                    onClick={estadoselection !== 3 ? TreZona : StateZona }
                                
                                >

                                <div className={`hijo-hijo-hijo
                                        ${estadoselection === 3 ? 'opacity-true' : ''}
                                    `}>
                                        <span className="hijo-center">

                                        </span>
                                        <span className="hijo-right">

                                        </span>
                                    </div>

                                <section className="box-cloud" > 

                                       <div className="box-cloud-def">

                                       <p>Detalles</p>
                                        <div className="img-arc"
                                        onClick={
                                                () => {
                                                    ActDetalles()
                                                    
                                                  

                                                }
                                        }
                                            >
                                            <img 
                                            src={x}/>
                                        </div>

                                       </div>

                                    </section>
                                    
                                </section>


                                </section>

                                
                                

                               </section>
                    

                    <section className={`favoritos-style`} >
                        <section className={`favoritos-mensaje`}>

                                            <h3 className="">
                                            Agregar propiedad
                                            </h3>

                                            <h4>Agrega tu propiedad </h4>

                                            <p>Como corredor, puedes agregar tu propiedad con los siguientes campos</p>
                        </section>



                    </section>

                    <section className='input-box-camp'>

                    <InputDynamic
                                    inputname={'Nombre Legal'}
                                    inputlabel={`${profile.first_name} ${profile.last_name1}`}
                                    info={'Este es el nombre que aparece en tu documento de viaje, que puede ser una licencia o un pasaporte.'}
                                    firstlabel={'Nombre'}
                                    seclabel={'Apellido'}
                                    action={'Guardar'}
                                    model={5}
                                    onFocus
                                    id1={"first_name"}
                                    name1={"first_name"}
                                    value1={datosusuario.first_name	 == null ? '' : datosusuario.first_name}
                                    value2={datosusuario.last_name1	 == null ? '' : datosusuario.last_name1}
                                    type1={"text"}
                                    type2={"text"}
                                    id2={"last_name1"}
                                    name2={"last_name1"}

                                    ApiCall={ActualizarDatosAction}
                                    onChange={onChange}
                                   
                                />

                    </section>

                            
                
                    </section>


                    

                    

                </section>
            </div>
            
                        <section className="BarraModalBack">
                                    

                                    <div className="head-e-77">
                                    <div className="icon-option">
                                    <img src={x} 
                                       onClick={WinProp}
                                    />
                                  
                                       
                                    </div>
                                    <div className="">
                                        {/* <img src={Logo} className="Logo-Givarts"/> */}

                                        <div className=" blackblack">
                                            <h2>¿Quieres comprar o Alquilar?</h2>
                                        <p className="">groundscript.com</p>
                                        </div>
                                    </div>
                                
                                    <div className="menu-container-right">

                                    
                                   

                                   

                                    </div>
                                        
                                        
                                    </div>
                        </section>
            
            </> : null  
        
        }
            </>


     );
}
 
export default WindowAddProp;