import React, { useState, createContext, useEffect} from 'react'
import clienteAxios from '../../config/axios'
import { Link, useNavigate } from 'react-router-dom'
import {  PRO , COM } from '../../microdata/dataLocation'


const ActJoemaCuentaContext = createContext();
const ActJoemaCuentaProvider = ({children}) => {
    const [location, setLoc] = useState({
        comarcas:COM,
        provincias:PRO,
    });
    const [cupones, setCupones] = useState(false); //// estado para creditos y cupones por invitacion
    const [datospersonales, setDatosPersonales] = useState(false); /// estado para los datos personales 
    const [herramientacorredores, setHCorredores] = useState(false); //// estado para los corredores
    const [impuesto, setImpuestos] = useState(false); // estado para los impuestos
    const [seguridad, setSeguridad] = useState(false); /// inicio de sesion y seguridad 
    const [notificaciones, setNotificaciones ] = useState(false); //// notificaciones 
    const [pagosycobros, setPagosYCobros ] = useState(false) /// pagos y cobros
    const [preferencias, setPreferenciasGlobal] = useState(false); //// preferencias globales
    const [privacidad, setPrivacidad ] = useState(false); /// privacidad 
    const [viajesdetrabajo, setViajesDeTrabajo] = useState(false); //// viajes de trabajo
        const Tcupones = () => {
                setCupones(!cupones)
            }
        const Tdatospersonales = () => {
            setDatosPersonales(!datospersonales)
        }
        const Therramienta = () => {
            setHCorredores(!herramientacorredores)
        }
        const TImpuesto = () => {
            setImpuestos(!impuesto)
        }
        const TSeguridad = () => {
            setSeguridad(!seguridad)
        }
        const TNotificaciones = () => {
            setNotificaciones(!notificaciones)
        }
        const TPagosYCobros = () => {
            setPagosYCobros(!pagosycobros)
        }
        const TPreferencias = () => {
            setPreferenciasGlobal(!preferencias)
        }
        const TPrivacidad = () => {
            setPrivacidad(!privacidad)
        }
        const TViajesDeTrabajo = () => {
            setViajesDeTrabajo(!viajesdetrabajo)
        }    
        ////////// aqui vamos a colocar el estado el objeto que tenemos alli
        const [alessa, AlessandraMontoya ] = useState({   
            id:null,
            T:'',
            D:'',
        })
        /// aqui vamos a colocar los inputs los focus ///
        const [focusinput, setFocusInput] = useState('')
        const [focus, setFocus] = useState('')
        /// aqui vamos a colocar el focus select
        const [focusselect , setFocusSelect] = useState({}) ///
        //// aqui vamos a colocar el focus del task
        const [focustask, setFocusTask ] = useState('')
        const MeEncantas = () => {
            window.scrollTo(0, 0)
            setFocusTask('')
            AlessandraMontoya({
                id:null,
                T:'',
                D:'',
            })
        }

       const [activarscroll, setActScroll ] = useState(false)

       const activarSc = () => {
        window.scrollTo(0, 0)
        setActScroll(!activarscroll)
        console.log(activarscroll)
       } 


        
    return (
        <ActJoemaCuentaContext.Provider
            value={{
                location, /// lugares
                cupones,
                Tcupones,
                datospersonales,
                Tdatospersonales,
                herramientacorredores,
                Therramienta,
                impuesto,
                TImpuesto,
                seguridad,
                TSeguridad,
                notificaciones,
                TNotificaciones,
                pagosycobros,
                TPagosYCobros,
                preferencias,
                TPreferencias,
                TPrivacidad,
                privacidad,
                TViajesDeTrabajo,
                viajesdetrabajo,
                alessa, AlessandraMontoya,
                MeEncantas,
                focusinput,
                setFocusInput,
                focus,
                setFocus,
                focusselect , 
                setFocusSelect,
                focustask, setFocusTask,
                activarSc,
                activarscroll


            }}
        >


            {children}
        </ActJoemaCuentaContext.Provider>

    )


}

export {
    ActJoemaCuentaProvider
}

export default ActJoemaCuentaContext;