import React, { useState, createContext, useEffect, useRef} from 'react'
import clienteAxios from '../../config/axios'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import {  PRO , COM } from '../../microdata/dataLocation'
import vista from '../../imagenes/modalicon/vista.svg'
import { useParams, useNavegate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';





const ActJoemaContext = createContext();

const ActJoemaProvider = ({children}) => {

    const [location, setLoc] = useState({
        comarcas:COM,
        provincias:PRO,
    
    });

    const [windowscloset, setWinClosetWin] = useState(false)


    // console.log(location.provincias[0])
    //// aqui vamos a colocar el estado del menu peque;o
    const [open, setO] = useState(false)
    const toggle = () => {
        setO(!open)
   
    }
    //// aqui vamos a colocar el estado de las ventanas principales
    const [win, setWin1 ] = useState(false);
    const WinAct = () => {
        setWin1(!win)
        window.history.pushState({ win: true }, '');
        setWinClosetWin(!windowscloset) 
        
    } 
    const [winlow, setWinLow] = useState(false);
    const WinActDos = () => {
        setWinLow(!winlow)
        setWinClosetWin(!windowscloset)
    }
    /////// esta estado hace referencia a las cuentas de perfil, configuraciones y esoo
    const [winacc, setWinAcc] = useState(false);
    const WinAccD = () => {
        setWinAcc(!winacc)
        window.history.pushState({ winacc: true }, ''); 
        setWinClosetWin(!windowscloset)
    }
    //// este estado responde a la ventana de busqueda general ,
    const [winsearch, setWinS ] = useState(false);
    const WinS = ()=>{
        setWinS(!winsearch)
        setWinClosetWin(!windowscloset)
    }
    ///// este estado , no estoy muy seguro, creo que repsonde a los mensajes
    const [winmensaje, setWinMensaje] = useState(false);
    const WinM = () => {
        setWinMensaje(!winmensaje)
        setWinClosetWin(!windowscloset)
    }
    /// este estado es para las notificaciones
    const [winnotificaciones, setWinNot] = useState(false);
    const WinNot = ()=> {
        setWinNot(!winnotificaciones)
        setWinClosetWin(!windowscloset)
    }
    ///// este proximo estado es para las compras
    const [wincompras, setWinCompras] = useState(false);
    const WinCompras = () => {
        setWinCompras(!wincompras);
        setWinClosetWin(!windowscloset)
    }
    ////// este proximo estado es para los favoritos
    const [winfavoritos, setWinFav] = useState(false);
    const WinFav = () => {
        setWinFav(!winfavoritos);
        setWinClosetWin(!windowscloset)
    }
    //// este proximo estado es para los idiomas y la moneda
    const [winidioma, setWinIdioma] = useState(false);
    const WinIdioma = () => { 
        setWinIdioma(!winidioma);
        setWinClosetWin(!windowscloset)
    }

    //// este estado es el que acciona agregar una propiedad 
    const [winprop, setWinProp] = useState(false);
    const WinProp = () => { 
        setWinProp(!winprop);
        setWinClosetWin(!windowscloset)
    } 
    


    const [img1win, setImg1] = useState('loading')
    const [img2win, setImg2] = useState('loading')
    const [img3win, setImg3] = useState('loading')
    const [img4win, setImg4] = useState('loading')
    const [img5win, setImg5] = useState('loading')
    const [Prop, setProp] = useState({})
    /////// aqui esta la parte que recoje el scroll y la posicion de el
    
    const [scrollPosition, setScrollPosition] = useState(0);
    const [prevScrollPosition, setPrevScrollPosition] = useState(0);
    const [isScrolled, setIsScrolled] = useState(true);
    //   const isScrolled = scrollPosition > 100
/////// ahora vamos a colocar la logica para que el modal se herede , el de seleccion

    const navigate = useNavigate()
    
    const handleClick = (id) => {
        // <Navigate to={`/core/${id}`} />
        // navigate(`/core/${id}`);
        console.log(`hola`)
    };

   
    useEffect(
        () =>{
          



            const handlePopstate = () => {
                setWin1(false)
                setWinAcc(false)
              };
              




            window.addEventListener('popstate', handlePopstate);
            
            return () => {
                window.removeEventListener('popstate', handlePopstate);
              };

              

             
        },[win])

        // console.log(popstate)

    return (
        <ActJoemaContext.Provider
            value={{
                location, /// lugares
                open, /// menu
                toggle, // menu
                setO, /// menu
                win,
                WinAct,
                img1win, setImg1, /// MODAL 1 
                img2win, setImg2, /// MODAL 1
                img3win, setImg3, /// MODAL 1
                img4win, setImg4, /// MDOAL 1
                img5win, setImg5, /// MODAL 1
                winlow, setWinLow, // Footer
                WinActDos,
                winacc,WinAccD, //perfil 
                winsearch,WinS, // busqueda
                winmensaje, WinM, // mensajes
                winnotificaciones, WinNot, // notificaciones
                wincompras, WinCompras, // compras
                winfavoritos,WinFav, // favoritos
                winidioma, WinIdioma,  // idioma y moneda
                winprop,WinProp, // Vetana de propiedades
                Prop, setProp,
                scrollPosition, setScrollPosition,
                prevScrollPosition, setPrevScrollPosition,
                isScrolled, setIsScrolled,
                handleClick,
                windowscloset
               
                
                

            }}
        >


            {children}
        </ActJoemaContext.Provider>

    )


}

export {
    ActJoemaProvider
}

export default ActJoemaContext;