import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Navigate, BrowserRouter as  Router, Routes, Route } from "react-router-dom";
import {  Link, useNavigate, useLocation } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import axiosAuth from '../config/axios';

import gimage from '../imagenes/google/icon.svg'

import useData from '../hooks/useData';

import BAlerta from '../Alertas/BAlerta'
import LoaderCenter from '../componentes/Loader/LoaderCenter';
import LogoT from '../../imagenes/TierrasPanama/logo1.png'



const CreateDomo = () => {

    const {setWinAlert} = useData();
    const [ redirectlog, setRedirect ] = useState(false);
    const { pathname } = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [first_name, setFirst] = useState('');
    const [last_name1, setLast1] = useState('');
    const [nickname, setNick] = useState('')
    const [alerta, setAlerta] = useState({
        msg:'',
        error:false,

    }) 



    const {  ActiveEN,ActiveES, lang, setAuth, auth } = useAuth();

    
    

    const Create = () => {
        navigate('/')
    }

    const Home = () => {
        navigate('/')
    }

    const Loggin = () => {
        navigate('/core')
    }
    const [ inn, guardarInn ] = useState({
        holder_email:1,
        holder_password:0,
    });

    const [ mostrarpassword , setMostrarPassword] = useState(false)
 

    


    const navigate = useNavigate()

    const Log = () => {

        navigate('/')

    }


    const onSubmit = e => {
        e.preventDefault()
        
        
        // elGuardarCargando(true)
        
        window.scrollTo(0, 0)
  
        // PrismaLoad()

        // validar que no haya campos vacios

        if(email.trim() === '' || password.trim() ==='') {
            
            setWinAlert({
                data: 'Todos los campos son obligatorios',
                status:false,
                open:true,
            });
            return

        } 

        if (password.length < 6) {
            
            setWinAlert({
                data: 'Todos los campos son obligatorios',
                status:false,
                open:true,
            });
            return
        }

        const RegistrarUsuario = async () => {
            setWinAlert({
                data: null,
                status:true,
                open:true,
            });
            try {
                const { data } = await axiosAuth.post('/api/client/register', {email, password,last_name1,first_name, nickname})
                
                // setAuth(data.success.user);
                
                setWinAlert({
                    data: data.message,
                    status:true,
                    open:true,
                });

                setRedirect(true)

                



            } catch (error) {

               
                setWinAlert({
                    data: error.response.data.message,
                    status:false,
                    open:true,
                });

                
                
            }
        }

        RegistrarUsuario({email,password,last_name1,first_name,nickname})


        

        // userStatusFun({email,password})
    }


    
    
    

    


    // if(pathname === '/' && auth.status === true )  return <LoaderCenter/> 

    return (  

            <>


<BAlerta
                redirectlog={redirectlog}
                setRedirect={setRedirect}
                />
            
           <section className="domo-content">
                        <section className="head-bar-domo">

                            <span className="img-svg">
                            

                            </span>

                        </section>

                        <form className="body-bar-domo" onSubmit={onSubmit}>

                                <div className="login-box-domo bg-ground ">

                                <div className='logo-tierras'>

                                            <img
                                                src={LogoT}
                                                alt="Imagen-Logo"
                                                className="img-logo-tierras"
                                                onClick={Home}
                                            />

                                            </div>
                                    <div className="domo-elemento-1">

                                        <span className="imagen-one">
                                            
                                        </span>

                                        {/* <span className="imagen-two">
                                        <h3>{`${lang.data.Domo.domocomun}`}</h3>
                                            <h2>{`${lang.data.Domo.create}`}</h2>

                                        </span> */}

                                        
                                        <span className="imagen-two">
                                            <h2>
                                                {/* {`${lang.data.Domo.domocomun}`} */}
                                               
                                                 Tierras Panama
                                                 
                                                </h2>
                                            <h3>{`${lang.data.Domo.create}`}</h3>

                                        </span>

                                        



                                    </div>

                                    <div className="domo-elemento-2">

                                        <div className="box-input-domo-doble">
                                        <input  className="domo-input"
                                        type="text"
                                        required
                                        placeholder={`${lang.data.Domo.first}`}
                                                                                    id="first_name"
                                                                                    name="first_name"
                                                                                    onChange={ e => 
                                                                                        setFirst(e.target.value)
                                                                                        
                                                                                    }
                                                                                    value={first_name}
                                        />
                                        <input  className="domo-input"
                                        type="text"
                                        id="last_name1"
                                        name="last_name1"
                                        required
                                        onChange={ e => 
                                            setLast1(e.target.value)
                                            
                                        }
                                        value={last_name1}
                                        placeholder={`${lang.data.Domo.last}`}

                                        />

                                        </div>

                                        <input  className="domo-input"
                                        type="text"
                                        id="nickname"
                                        
                                                                name="nickname"
                                                                value={nickname}
                                                                required
                                                                onChange={ e => setNick(e.target.value)} 
                                        placeholder={`${lang.data.Domo.nickname}`}
                                        />



                                        <input  className="domo-input"
                                        type="text"
                                        id="email"
                                        
                                                                name="email"
                                                                value={email}
                                                                required
                                                                onChange={ e => setEmail(e.target.value)} 
                                        placeholder={`${lang.data.Domo.email}`}
                                        />
                                        
                                        
                                     
                                        <input className="domo-input"
                                            type="password"
                                            id="password"
                                                            name="password"
                                                            required
                                                            onChange={ e => 
                                                                setPassword(e.target.value)
                                                            }
                                                            value={password}
                                            placeholder={`${lang.data.Domo.password}`}
                                        />
                                        



                                    </div>

                                    <button className="btn-domo" type="submit">
                                    {`${lang.data.Domo.create}`}
                                        </button>

                                        <h2 className="create-link-domo">{`${lang.data.Domo.alr}`} <span className="create-domo" onClick={Log}> {`${lang.data.Domo.signin}`} </span></h2>

                                         




                                </div>

                                <div className="social-box-login-domo">
                                    <div className="or-social-domo">{`${lang.data.Domo.ousa}`}</div>

                                    <div className="container-social-domo">
                                        <p>{`${lang.data.Domo.continuewith}`}</p>
                                    <img src={gimage} alt="google-icon"/>

                                    </div>



                                </div>



                            
                        </form>
           </section>
          
            </>


    );
}
 
export default CreateDomo;