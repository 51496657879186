import React from 'react'
import useAct from '../../hooks/useAct';
import key from '../../imagenes/modalicon/key.svg'
import pets from '../../imagenes/modalicon/pets.svg'
import auto from '../../imagenes/modalicon/auto.svg'
import chat from '../../imagenes/modalicon/chat.svg'
import wifi from '../../imagenes/modalicon/wifi.svg'
import vista from '../../imagenes/modalicon/vista.svg'
////// aqui vamos a colocar el otro estado
import SearchBar from './SearchBar.jsx'
import menu01 from '../../imagenes/iconos/01menu.svg'
import menu02 from '../../imagenes/iconos/02menu.svg'
import menu03 from '../../imagenes/iconos/03menu.svg'
import B from '../../imagenes/iconos/WorldAir.svg'
////////////////////
import x from '../../imagenes/iconos/x.svg'

import h from '../../imagenes/iconos/heartilljoe.svg'
import m from '../../imagenes/iconos/Mem.svg'
import xx from '../../imagenes/iconosacc/x3.svg'
import xxx from '../../imagenes/iconos/Swhite.svg'
import { useParams } from 'react-router-dom';


const WindowSelect = () => {


    const { id } = useParams()

    const {
        win,
        WinAct,
        img1win, setImg1,
        img2win, setImg2,
        img3win, setImg3,
        img4win, setImg4,
        img5win, setImg5 ,
             
        WinAccD, // acciones de perfil cuentas
        WinM, // accciones de mensaje
        WinNot, // aciones de notificaciones
        WinFav, /// accciones para los favoritos
        WinCompras, // aacci0ones para la ventana de compras y alquiler
        WinIdioma, // aaciones para levantar la ventana de idioma
        open, /// variable para reconocer el menu
        toggle, /// accionador para abrir menu y cerrar
        Prop
    } = useAct()

    

    return ( 
            <>
                {
                    win === true  ? <>

                   
                

                    
                   
                         

            <div  className={` windows-selection ${win !== false ? ' op-true adelante ':''}  ${win !== true ?'animation-open-option-close  op-none':''}`}>
                       
                <section className="yeah-scroll">

             


                    <section className="bar-bar-joema" >

                 <p>   GroundScript  </p>   

                    </section>
                    

                    <section className="box-header-joema-modal">
                    <div className="head-bienes">
                        <h2>{Prop.text}</h2>
                    </div>
                    

                    <div className="body-bienes">
                                <div className="ground-ground">
                                <p>{Prop.puntuacion} </p>
                                <p>reseñas</p>
                                <p>{Prop.provincia}, {Prop.distrito}, {Prop.corregimiento}</p>
                            </div>
                            <div className="ground-ground text-center d-none">
                                <p>Compartir</p>
                                <p>Guardar</p>
                            </div>
                    </div>
                    </section>

                    <section className="box-images-joema">

                        <div className="box-img-1-joema">
                            
                            <div
                            className=""  style={{ background: `url(${img1win})` }} 
                            >
                                
                            </div>

                        </div>
                         
                        <div className="box-img-2-joema">

                        <div
                            className=""  style={{ background: `url(${img2win})` }} 
                            >
                                
                            </div>


                            <div
                            className=""  style={{ background: `url(${img3win})` }} 
                            >
                                
                            </div>


                            <div
                            className=""  style={{ background: `url(${img4win})` }} 
                            >
                                
                            </div>


                            <div
                            className=""  style={{ background: `url(${img5win})` }} 
                            >
                                
                            </div>

                            
                        

                        </div>

                    </section>


                    <section className="box-information">

                        <div className="box-header-general">
                        <div className="head-bienes">
                        <h2>{Prop.provincia}, {Prop.distrito}, {Prop.corregimiento}</h2>
                    </div>
                    

                    <div className="body-bienes">
                                <div className="ground-ground">
                                {/* <p>2huespedes</p>
                                <p>- 1habitación</p>
                            */}
                                 <p>{Prop.label}</p> 
                                <p> {Prop.ncamas} camas</p>
                            </div>
                            <div className="ground-ground text-center d-none">
                                <p>Compartir</p>
                                <p>Guardar</p>
                            </div>
                    </div>


                        </div>
                        <div className="box-header-general box-header-general-extra">

                            

                            <div className="icon-card-yeah-xx">
                                            <div className="">
                                                <img src={xx} className="icon-normal-size"/>
                                            </div>

                                            <div className="pl-2">
                                                <h3>$ {Prop.pornoche}</h3>
                                                <p>Precio por noche.</p>
                                            </div>
                                           
                            </div>  
                            <div className="icon-card-yeah-xx">
                            <div className="">
                                                <img src={xxx} className="icon-normal-size"/>
                                            </div>

                                            <div className="pl-2">
                                                <p>$ {Prop.total}</p>
                                                <h3>Total.</h3>
                                            </div>
                                           
                            </div>  

                          
                            

                        </div>

                        <div className="box-header-general box-header-general-extra d-none">

                            <div className="icon-card-yeah-xx">

                                <div className="">
                                    <img src={key} className="icon-normal-size"/>
                                </div>
                                
                                <div className="pl-2">
                                    <h3>¡Fascinante experiencia de llegada!</h3>
                                    <p>El 95 % de los últimos huéspedes han valorado con 5 estrellas el proceso de llegada.</p>
                                </div>

                            </div>

                            <div className="icon-card-yeah-xx">
                                            <div className="">
                                                <img src={pets} className="icon-normal-size"/>
                                            </div>

                                            <div className="pl-2">
                                                <h3>Las mascotas son bienvenidas</h3>
                                                <p>Lleva a tus mascotas al alojamiento.</p>
                                            </div>
                             </div>  
                             <div className="icon-card-yeah-xx">
                                            <div className="">
                                                <img src={chat} className="icon-normal-size"/>
                                            </div>

                                            <div className="pl-2">
                                                <h3>Gran comunicación</h3>
                                                <p>El 90 % de los huéspedes recientes evaluaron a Ronangel con una calificación de 5 estrellas en comunicación.</p>
                                            </div>
                             </div>

                        </div>

                        <div className="box-header-general edit-air">

                            <h3>AirGroundscript</h3>

                            <p>Todas las reservaciones incluyen protección gratuita en caso de que el anfitrión cancele, de que haya imprecisiones en el anuncio o de que surjan otros inconvenientes, como problemas al momento de hacer el check-in.</p>
                            <button>Más información</button>


                        </div>

                        <div className="box-header-general edit-air-services">

                                <h3>Lo que este lugar ofrece</h3>

                                <div className="grid-out">
                                <div className="icon-card-yeah-xx">
                                    <div className="">
                                        <img src={wifi} className="icon-normal-size-2"/>
                                    </div>
                                    <div className="pl-2">
                                        <p>WiFi</p>
                                    
                                    </div>
                                </div>
                                <div className="icon-card-yeah-xx">
                                    <div className="">
                                        <img src={auto} className="icon-normal-size-2"/>
                                    </div>
                                    <div className="pl-2">
                                        <p>Estacionamiento</p>
                                    
                                    </div>
                                </div>
                                <div className="icon-card-yeah-xx">
                                    <div className="">
                                        <img src={vista} className="icon-normal-size-2"/>
                                    </div>
                                    <div className="pl-2">
                                        <p>Vista al océano</p>
                                    
                                    </div>
                                </div>
                                <div className="icon-card-yeah-xx">
                                    <div className="">
                                        <img src={vista} className="icon-normal-size-2"/>
                                    </div>
                                    <div className="pl-2">
                                        <p>Vista natural</p>
                                    
                                    </div>
                                </div>

                                </div>

                              


                                <button>Mostrar todos los servicios</button>


                        </div>





                    </section>

                    <section className="windows-selection-hola-read">
                                <section className="airbox-joema">
                                <div className="air-box-bx">
                                        <h2>Asistencia</h2>
                                        <p>Centro de ayuda</p>
                                        <p>GroundScripCover</p>
                                        <p>Apoyo a las personas con discapacidad</p>
                                        <p>Opciones de cancelacion</p>
                                        <p>Nuestra respuesta frente al COVID-19</p>
                                        <p>Denunciar un problema en el vecindario</p>
                                    </div>
                                
                                    <div className="air-box-bx">
                                        <h2>Comunidad</h2>
                                        <p>Luchamos contra la discriminacion</p>
                                        <p>Alojamiento de ayuda en caso de catastrofe</p>
                                    
                                    </div>
                                
                                    <div className="air-box-bx">
                                        <h2>Modo corredor</h2>
                                        <p>Pon tu propiedad</p>
                                        <p>GroundScripCover para corredores </p>
                                        <p>Apoyo a las personas con discapacidad</p>
                                        <p>Recursos para corredores</p>
                                        <p>Visita el foro de la comunidad</p>
                                        <p>Cómo brindar servicios de Corredor de forma responsable</p>
                                    </div>
                                
                                    <div className="air-box-bx">
                                        <h2>GroundScrip air</h2>
                                        <p>Sala de prensa</p>
                                        <p>Más información</p>
                                        <p>Carta de nuestros fundadores</p>
                                        <p>Carreras</p>
                                        <p>Inversionistas</p>
                                
                                    </div>
                                </section>
                          </section>


                    

                    

                </section>
            </div>

            <section 
                        className={` box-barra-modal ${win !== false ? '  ':''}  ${win !== true ?' ':''}`}
                        >

                        
                        <section className="BarraModalBack">
                                    

                                    <div className="head-e-77">
                                    <div className="icon-option">
                                    <img src={x} 
                                    onClick={WinAct}
                                    />
                                  
                                       
                                    </div>
                                    <div className="">
                                        {/* <img src={Logo} className="Logo-Givarts"/> */}

                                        <div className=" blackblack">
                                            <h2>¿Quieres comprar o Alquilar?</h2>
                                        <p className="">groundscript.com</p>
                                        </div>
                                    </div>
                                
                                    <div className="menu-container-right">

                                    
                                    <div className="icon-option">
                                    <img src={B} onClick={WinIdioma}/>
                                    <img 
                                            src={h}
                                                                         
                                        />
                                        <img 
                                            src={m}
                                                                          
                                        />
                                    </div>

                                   

                                    </div>
                                        
                                        
                                    </div>
                        </section>

                 </section>

                                    
                  
                    </> : null
                }
            
         
            </>


     );
}
 
export default WindowSelect;