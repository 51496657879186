import React, { useState } from 'react'
import SearchBar from './SearchBar.jsx'
import Logo from '../../imagenes/logog.png'
import B from '../../imagenes/iconos/WorldAir.svg'
import menuAir from '../../imagenes/iconos/menuAir.svg'
import menu01 from '../../imagenes/iconos/01menu.svg'
import menu02 from '../../imagenes/iconos/02menu.svg'
import menu03 from '../../imagenes/iconos/03menu.svg'

import { useNavigate } from 'react-router-dom'
import useAct from '../../hooks/useAct.jsx'
import useAuth from '../../hooks/useAuth.jsx'
import useData from '../../hooks/useData.jsx'
import LogoT from '../../../imagenes/TierrasPanama/logo1.png'



const HeaderBar = () => {

        ///// este componente es especial porque trae las acciones para lenvatar las ventanas
    
    const { 
        winacc, //// variables
        WinAccD, // acciones de perfil cuentas
        WinM, // accciones de mensaje
        WinNot, // aciones de notificaciones
        WinFav, /// accciones para los favoritos
        WinCompras, // aacci0ones para la ventana de compras y alquiler
        WinIdioma, // aaciones para levantar la ventana de idioma
        WinProp, /// acciones para levantar la ventanan de agregar propiedad
        open, /// variable para reconocer el menu
        toggle, /// accionador para abrir menu y cerrar
        setO,
        isScrolled
    }
         = useAct()
    const {
            KillApp
               } 
    
    = useAuth()

    const {
        profile
    } = useData()

    



    return ( 
            // <section className={`Headerbar scrolled-header-out ${isScrolled ? 'scrolled-header-out' : 'scrolled-header-in'}` } >
            <section className={`Headerbar scrolled-header-out ${isScrolled ? 'scrolled-header-out' : ''}` } >
{/* 
                <section className="background-epic">

                    <div className="eleme-bg-1">

                    </div>

                    <div className="eleme-bg-2">
                        
                    </div>

                    <div className="eleme-bg-3">
                        
                    </div>


                </section> */}

                <section className="Headerbar-box">
                             <div className="head-e-1">
                                {/* <img src={Logo} className="Logo-Givarts"/> */}
                                
                                <div className="ask-ground">
                                <div className='logo-tierras'>
                                        <img
                                            src={LogoT}
                                            alt="Imagen-Logo"
                                            className="img-logo-tierras-02"
                                        />

                                        </div>
                               <div>
                               <h2>¿Quieres comprar o Alquilar?</h2>
                                {/* <p className="">groundscript.com</p> */}
                               </div>
                                </div>
                            </div>
                            <div className="head-e-2">
                                <SearchBar/>
                            </div>

                            <div className="head-e-3">
                            <div className="menu-container-right">

                            <div className="Header-action">
                            <p>Bienvenido, <span className="apiname">{profile.first_name}</span> </p> 
                                
                                   
                            </div>

                               <div className="icon-option">

                               <img src={B} onClick={WinIdioma}/> 

                               <img 
                                    src={menu01}
                                    onClick={WinM}                                
                                />
                                
                                <img 
                                    src={menu03}
                                    onClick={WinNot}                                
                                />


                               </div>


                            <div className="single-bar" onClick={toggle}>
                                {open === true ?
                                <>
                                 <div className={`menu-leve-leve  ${ open === false ? 'willoff d-none ':' willon d-grid' } `}>

                                    <div>
                                        <p onClick={WinM}>Mensajes</p>
                                        <p onClick={WinNot}>Notificaciones</p>
                                        <p onClick={WinCompras}>Compras, Alquileres</p>
                                        <p onClick={WinFav}> Lista de favoritos</p>
                                        
                                    </div>
                                    <div className="border-top font-light">
                                    <p onClick={WinProp}> Agrega propiedad</p>
                                        <p onClick={WinAccD}>Cuenta</p>
                                    </div>
                                    <div className="border-top font-light">
                                        <p>Ayuda</p>
                                        <p onClick={KillApp}>Cerrar la sesión</p>
                                    </div>

                                    </div>
                                </> :null

                                }
                               

                               <div className={`img-box-icon-joe `}>

                                        <img 
                                                src={menu02}                                
                                            />
                               </div>


                                <img
                                className='img-max-yeah'
                                    src={`https://a0.muscache.com/defaults/user_pic-50x50.png?`}
                                />



                               


                            </div>

                            </div>
                                
                                
                            </div>
                </section>

                {/* <section className="Headerbar-box-box">
                             <div className="head-e-1">
                            </div>

                            <div className="head-e-2">

                                ¿Quieres comprar o Alquilar?
                                
                            </div>

                            <div className="head-e-3">
                                
                            </div>
                </section> */}

            </section>

     );
}
 
export default HeaderBar;