import React from 'react'
import A from '../../imagenes/iconos/ArrowUp.svg'
import B from '../../imagenes/iconos/WorldAir.svg'
import useAct from '../../hooks/useAct';

const FooterBar = () => {





    const {
        winlow,
        WinActDos,
        scrollPosition,
        isScrolled
    } = useAct()

    


    return ( 

        <>

                <section className={`Footerbar ${isScrolled ? 'scrolled-footer-out' : 'scrolled-footer'} `}
                
                
                
                >

                <div className="foot-e-1">

                <p>
                © 2023 GroundScript
                </p>

                <p>Privacidad</p>

                <p>Términos</p>

                <p>Conexión</p>



                </div>


                <div className="foot-e-3 ">

                <div className="Footer-right-Center">
                         <img src={B}/>     Español(PA) 
                </div>

                <div className="Footer-right-Centerr">
                $ USD 
                    </div>

                   

                     <div className="Footer-right-Joe" onClick={WinActDos}>
                    Asistencia y recursos <img src={A}/>    
                    </div>
                        
                    
                </div>

                </section>

        </>

     );
}
 
export default FooterBar;